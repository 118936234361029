function openLanguageModal() {
  $("#language-modal").modal("show");
}

function closeLanguageModal() {
  $("#language-modal").modal("hide");
}

function printInLanguageModal(what) {
  $("#language-modal .modal-body").html(what);
}

function loadLanguageCreateModal() {
  $.get(
    "languages/create",
    null,
    function(response) {
      printInLanguageModal(response);
      openLanguageModal();
    }
  );
}

function loadLanguageEditModal(id) {
  $.get(
    "languages/" + id + "/edit",
    null,
    function(response) {
      printInLanguageModal(response);
      openLanguageModal();
    }
  );
}

$("#add-new-language-btn").click(function(e) {
  e.preventDefault();
  loadLanguageCreateModal();
});

$(".edit-language").click(function(e) {
  e.preventDefault();
  loadLanguageEditModal($(this).attr("data-id"));
});

$('body').on('submit', '#language-form', function(e) {
    var $this = $(this);
    var form = new FormData($this[0]);
    e.preventDefault();

    $.ajax({
      url : $this.attr('action'),
      type: 'POST',
      data: form,
      processData: false,
      contentType: false,
      success: function(response) {
        if (response === 'Successfully added new language.' || response === 'Language successfully updated.') {
          closeLanguageModal();
          message = `<div class="alert alert-success">${response}</div>`;
          $('#successMessage').html(message);
          setTimeout('location.reload(true)', 1000);
        } else {
          message = `<div class="alert alert-danger">${response}</div>`;
          $('#errorMessage').html(message);
        }
      },
      error: function(data) {
      }
    });
});
