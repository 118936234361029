var regexCounter = 1;
var customButtonCounter = 1;
var url = window.location.href;
var values = {};
var customButtonValues = {};

$('#payments-form-create #custom-button-section').on('click', '#add-custom-button', function (e) {
  e.preventDefault();

  customButtonCounter++;
  addcustomButtonCounterInput();
});

$('#payments-form-edit #custom-button-section').on('click', '#add-custom-button', function (e) {
  e.preventDefault();

  customButtonCounter++;
  addCustomButtonInput();
});

$('body').on('click', '.glyphicon-custom-button', function (e) {
  let elementId = $(this).attr('id').split('-')

  if (elementId[2] && $(`#custom-button-${elementId[3]}`)) {
    $(`#custom-button-${elementId[3]}`).remove();
  }
});

function addCustomButtonInput() {
  $('#custom-button-inputs-key-value').append(`<div class="input-group display-flex-align margin-bottom15" id="custom-button-${customButtonCounter}">
    <input id="custom-button-key-${customButtonCounter}" type="text" class="form-control key-gray-color" custom-button="Key">
    <input id="custom-button-value-${customButtonCounter}"type="text" class="form-control" custom-button="value">
    <span style="padding: 7px" id="remove-custom-button-${customButtonCounter}" class="glyphicon glyphicon-remove glyphicon-custom-button" aria-hidden="true"></span>
  </div>`);
}

if (!$('#is_non_rest_provider').is(":checked")) {
  $('#non-rest-provider-params').hide();
}

$('#is_non_rest_provider').change(function () {
  if ($(this).is(":checked")) {
    $('#non-rest-provider-params').show('fast');

    return;
  }

  $('#non-rest-provider-params').hide('fast');
});

$('#payments-form-create #regex-section').on('click', '#add-regex', function (e) {
  e.preventDefault();

  regexCounter = regexCounter + 1;
  addRegexInput();
});

$('#payments-form-edit #regex-section').on('click', '#add-regex', function (e) {
  e.preventDefault();

  regexCounter = regexCounter + 1;
  addRegexInput();
});

$('body').on('click', '.glyphicon-regex', function (e) {
  let elementId = $(this).attr('id').split('-')

  if (elementId[2] && $(`#regex-${elementId[2]}`)) {
    $(`#regex-${elementId[2]}`).remove();
  }
});

function addRegexInput() {
  $('#regex-inputs-key-value').append(`<div class="input-group display-flex-align margin-bottom15" id="regex-${regexCounter}">
    <input id="regex-key-${regexCounter}" type="text" class="form-control key-gray-color" regex="Key">
    <input id="regex-value-${regexCounter}"type="text" class="form-control" regex="value">
    <span style="padding: 7px" id="remove-regex-${regexCounter}" class="glyphicon glyphicon-remove glyphicon-regex" aria-hidden="true"></span>
  </div>`);
}

$('body').on('submit', '#payments-form-create', function (e) {
  e.preventDefault();
  var $this = $(this);
  let websiteId = $('#website-id').attr('data-id');
  var token = $('#website-id').data('token');
  var isMobile = $('#website-id').data('mobile');

  var form = new FormData(this);
  for (i = 0; i < regexCounter; i++) {
    if ($(`#regex-key-${i + 1}`).val() && $(`#regex-value-${i + 1}`).val()) {
      values[$(`#regex-key-${i + 1}`).val()] = $(`#regex-value-${i + 1}`).val();
    }
  }
  regex = JSON.stringify(values);
  const customButtons = getCustomButtons();

  $.ajax({
    url: $this.attr('action'),
    type: 'post',
    data: form,
    processData: false,
    contentType: false,
    success: function (response) {
      if (response && response.id) {
        updateRegex(websiteId, response, token, regex, customButtons, isMobile);
      }
    },
    error: function (error) {
      $errors = error.responseJSON.errors;
      errorsHtml = '';

      $.each($errors, function (key, value) {
        errorsHtml += '<li>' + value[0] + '</li>';
      });

      $('#payments-create-fail').css('display', 'block');
      $('#payments-create-fail').html(errorsHtml);
      $('html, body').animate({ scrollTop: 0 }, 'slow');
    }
  });
});

function getCustomButtons() {
  for (i = 0; i < customButtonCounter; i++) {
    if ($(`#custom-button-key-${i + 1}`).val() && $(`#custom-button-value-${i + 1}`).val()) {
      customButtonValues[$(`#custom-button-key-${i + 1}`).val()] = $(`#custom-button-value-${i + 1}`).val();
    }
  }
  return JSON.stringify(customButtonValues);
}

$(document).ready(function () {
  let regex = $('#payments-form #regex').val();
  if ($('#regex').length) {   // check if div with id 'games-included' exist
    let variableFromLaravel = $("#regex").text();
    if (variableFromLaravel) {
      regex = JSON.parse(variableFromLaravel);
      _.each(regex, (value, key) => {
        values[key] = value;
        $('#regex-inputs-key-value').append(`<div class="input-group display-flex-align margin-bottom15" id="regex-${regexCounter}">
            <input id="regex-key-${regexCounter}" type="text" value="${key}" class="form-control key-gray-color" regex="Key">
            <input id="regex-value-${regexCounter}"type="text" value="${value}" class="form-control" regex="value">
            <span style="padding: 7px" id="remove-regex-${regexCounter}" class="glyphicon glyphicon-remove glyphicon-regex" aria-hidden="true"></span>
          </div>`);
        regexCounter++;
      });
    }
  }

  let customButtons = $('#payments-form #custom-buttons').val();
  if ($('#custom-button').length) {
    let variableFromLaravel = $("#custom-button").text();
    if (variableFromLaravel) {
      customButtons = JSON.parse(variableFromLaravel);
      _.each(customButtons, (value, key) => {
        values[key] = value;
        $('#custom-button-inputs-key-value').append(`<div class="input-group display-flex-align margin-bottom15" id="custom-button-${customButtonCounter}">
            <input id="custom-button-key-${customButtonCounter}" type="text" value="${key}" class="form-control key-gray-color" custom-button="Key">
            <input id="custom-button-value-${customButtonCounter}"type="text" value="${value}" class="form-control" custom-button="value">
            <span style="padding: 7px" id="remove-custom-button-${customButtonCounter}" class="glyphicon glyphicon-remove glyphicon-custom-button" aria-hidden="true"></span>
          </div>`);
          customButtonCounter++;
      });
    }
  }
});

function updateRegex(websiteId, response, token, regex, customButtons, isMobile) {
  $.ajax({
    url: '/websites/' + websiteId + '/payments/update-payments-regex' + '/' + response.id,
    type: 'post',
    dataType: 'json',
    data: { _method: 'post', _token: token, regex: regex, custom_buttons: customButtons, isMobile: isMobile },
    success: function (response) {
      values = {};
      regexCounter = 1;
      window.location.replace(
        window.location.origin + `/websites/${websiteId}/payments?type=${response.type}&isMobile=${isMobile}`
      );
    },
    error: function (data) {
    }
  });
}

$('body').on('submit', '#payments-form-edit', function (e) {
  e.preventDefault();
  var $this = $(this);
  let websiteId = $('#website-id').attr('data-id');
  var token = $('#website-id').data('token');
  var isMobile = $('#website-id').data('mobile');
  let values = {};

  var form = new FormData(this);
  for (i = 0; i < regexCounter; i++) {
    if ($(`#regex-key-${i + 1}`).val() && $(`#regex-value-${i + 1}`).val()) {
      values[$(`#regex-key-${i + 1}`).val()] = $(`#regex-value-${i + 1}`).val();
    }
  }
  regex = JSON.stringify(values);
  const customButtons = getCustomButtons();

  $.ajax({
    url: $this.attr('action'),
    type: 'post',
    data: form,
    processData: false,
    contentType: false,
    success: function (response) {
      if (response && response.id) {
        updateRegex(websiteId, response, token, regex, customButtons, response.is_mobile)
      }
    },
    error: function (error) {
      console.log(error.responseJSON);
      $errors = error.responseJSON.errors;
      errorsHtml = '';

      $.each($errors, function (key, value) {
        errorsHtml += '<li>' + value[0] + '</li>';
      });

      $('#payments-update-fail').css('display', 'block');
      $('#payments-update-fail').html(errorsHtml);
      $('html, body').animate({ scrollTop: 0 }, 'slow');
    }
  });
});
