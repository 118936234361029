function initGameFilter() {
  if (!jQuery("#game-filters ul").length) {
    return;
  }

  parseGameFiltersValue();
  formatGameFiltersBeforeFormSubmit();
  handleAddGameFilter();
  handleShowGameFilterContent();
  handleGameFilterPhraseKeyChange();
  handleAddGameFilterSelection();
  handleCopyGameFilterSelection();
  handleRemoveGameFilter();
  handleRemoveGameFilterSelection();
  handleFormatGameFilters();

  $("#game-filters > ul").sortable({
    update: function( event, ui ) { formatGameFilters(); }
  });
  
  setSortableOnFilterSelection();
}

function setSortableOnFilterSelection() {
  $("#game-filters > ul .game-filter-selections").sortable({
    update: function( event, ui ) { formatGameFilters(); }
  });
}

function parseGameFiltersValue() {
  var rawValue = jQuery("#game-filters-value").val();
  if (!rawValue) {
    return;
  }
  var gameFilters = JSON.parse(rawValue);

  if (!gameFilters.length) {
    return;
  }

  gameFilters.forEach(function (filter) {
    jQuery("#game-filters > ul").append(getGameFilterHtml(filter.phrase_key, filter.selections));
  });
}

function handleAddGameFilter() {
  jQuery("#game-filters .add-game-filter").click(function (e) {
    jQuery("#game-filters > ul").append(getGameFilterHtml(null, null));
    formatGameFilters();
  });
}

function handleShowGameFilterContent() {
  jQuery("#game-filters > ul").on('click', '.game-filter-header', function (e) {
    var closed = jQuery(this).parent().find('.game-filter-content').css('display') === 'none';
    jQuery("#game-filters > ul .game-filter-content").slideUp();
    if (closed) {
      jQuery(this).parent().find('.game-filter-content').slideToggle('fast');
    }
  });
}

function handleGameFilterPhraseKeyChange() {
  jQuery("#game-filters > ul").on('change', '.game-filter-phrase-key', function (e) {
    jQuery(this).closest('li').find('.game-filter-header').html(jQuery(this).val());
    formatGameFilters();
  });
}

function getGameFilterHtml(phraseKey, selections) {
  var title = phraseKey ? phraseKey : 'New Filter';
  praseKey = phraseKey ? phraseKey : '';
  selections = selections ? selections : [];
  var html = '<li>';
  
  html += '<div class="game-filter-header" style="padding: 10px; background: #f8f8f8; border: 1px solid #eee;">' + title + '</div>';
  html += '<div class="game-filter-content" style="display: none; padding: 10px; border: 1px solid #eee;">';
  
  html += '<div class="form-group">';
  html += '<label>Translation phrase key:</label>';
  html += '<input type="text" class="game-filter-phrase-key form-control" value="' + praseKey + '">';
  html += '</div>';
  html += '<div class="game-filter-selections">';
  selections.forEach(function (selection) {
    html += getGameFilterSelection(
      selection.template_id,
      selection.game_position,
      selection.selection_position,
      selection.space_after
    );
  });
  html += '</div>';
  html += '<div class="form-group">';
  html += '<label>Game selection definition:</label>';
  html += '<button type="button" class="btn btn-default add-game-filter-selection">Add selection</button>';
  html += '</div>';
  
  html += '<div class="form-group">';
  html += '<button type="button" class="btn btn-danger remove-game-filter margin-right10">Remove filter</button>';
  html += '<button type="button" class="btn btn-default copy-game-filter">Copy filter</button>';
  html += '</div>';
  
  html += '</div>';
  html += '</li>';

  return html;
}

function getGameFilterSelection(template_id, game_position, selection_position, space_after) {
  var spaceAfterChecked = space_after ? ' checked' : '';
  var html = '<div class="row game-filter-selection" id="test">';
  html += '<div class="col-md-3">';
  html += '<input type="number" required class="form-control game-filter-selection-1" placeholder="Template ID" value="' + template_id + '">';
  html += '</div>';
  html += '<div class="col-md-3">';
  html += '<input type="number" required class="form-control game-filter-selection-2" placeholder="Game position" value="' + game_position + '">';
  html += '</div>';
  html += '<div class="col-md-3">';
  html += '<input type="number" required class="form-control game-filter-selection-3" placeholder="Selection position" value="' + selection_position + '">';
  html += '</div>';
  html += '<div class="col-md-3">';
  html += '<button type="button" class="remove-game-filter-selection btn btn-default">Remove</button>';
  html += '<div class="checkbox"><label><input type="checkbox" class="game-filter-selection-space" value="" ' + spaceAfterChecked + '>Add space after</label></div >';
  html += '</div>';
  html += '</div>';

  return html;
}

function handleAddGameFilterSelection() {
  jQuery("#game-filters > ul").on('click', '.add-game-filter-selection', function (e) {
    jQuery(this).parent().parent().find(".game-filter-selections").append(getGameFilterSelection('', '', '', false));
    setSortableOnFilterSelection();
    formatGameFilters();
  });
}

function handleCopyGameFilterSelection() {
  jQuery("#game-filters > ul").on('click', '.copy-game-filter', function (e) {
    jQuery(this).closest("li").clone().appendTo("#game-filters ul");
    formatGameFilters();
  });
}

function handleRemoveGameFilter() {
  jQuery("#game-filters > ul").on('click', '.remove-game-filter', function (e) {
    jQuery(this).closest('li').remove();
    formatGameFilters();
  });
}

function handleRemoveGameFilterSelection() {
  jQuery("#game-filters > ul").on('click', '.remove-game-filter-selection', function (e) {
    jQuery(this).closest('.game-filter-selection').remove();
    formatGameFilters();
  });
}

function handleFormatGameFilters() {
  jQuery(".format-game-filters").click(function () {
    formatGameFilters();
  });
}

function formatGameFilters() {
  var result = [];

  jQuery("#game-filters > ul li").each(function () {
    result.push({
      'phrase_key': jQuery(this).find('.game-filter-phrase-key').val(),
      'selections': formatGameFilterSelections(jQuery(this).find('.game-filter-selections'))
    });
  });

  jQuery("#game-filters-value").val(JSON.stringify(result));
}

function formatGameFilterSelections(element) {
  var result = [];

  element.find('.game-filter-selection').each(function () {
    result.push({
      'template_id': jQuery(this).find('.game-filter-selection-1').val(),
      'game_position': jQuery(this).find('.game-filter-selection-2').val(),
      'selection_position': jQuery(this).find('.game-filter-selection-3').val(),
      'space_after': !!jQuery(this).find('.game-filter-selection-space:checked').length
    })
  });

  return result;
}

function formatGameFiltersBeforeFormSubmit() {
  jQuery("form").submit(function (e) {
    formatGameFilters();
  });
}

initGameFilter();
