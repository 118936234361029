function initMarketTag() {
  if (!jQuery("#market-selection-tag ul").length) {
    return;
  }

  parseMarketTagValue();
  formatMarketTagBeforeFormSubmit();
  handleAddMarketTag();
  handleShowMarketTagContent();
  handleMarketTagPhraseKeyChange();
  handleMarketTagMarketIdKeyChange();
  handleAddMarketTagSelection();
  handleCopyMarketTagSelection();
  handleRemoveMarketTag();
  handleRemoveMarketTagSelection();
  handleFormatMarketTag();
  handleTopMarketTagSelection();
  handleNewMarketTagSelection();

  $("#market-selection-tag > ul").sortable({
    update: function( event, ui ) { formatMarketTag(); }
  });

  setSortableOnMarketTagSelection();
}

function setSortableOnMarketTagSelection() {
  $("#market-selection-tag > ul .market-selection-tag-tags").sortable({
    update: function( event, ui ) { formatMarketTag(); }
  });
}

function parseMarketTagValue() {
  var rawValue = jQuery("#market-selection-tag-value").val();
  if (!rawValue) {
    return;
  }
  var marketTags = JSON.parse(rawValue);

  if (!marketTags.length) {
    return;
  }

  marketTags.forEach(function (tag) {
    jQuery("#market-selection-tag > ul").append(getMarketTagHtml(tag.phrase_key, tag.tags, tag.market_id));
  });
}

function handleAddMarketTag() {
  jQuery("#market-selection-tag .add-market-selection-tag").click(function (e) {
    jQuery("#market-selection-tag > ul").append(getMarketTagHtml(null, null, null));
    formatMarketTag();
  });
}

function handleShowMarketTagContent() {
  jQuery("#market-selection-tag > ul").on('click', '.market-selection-tag-header', function (e) {
    var closed = jQuery(this).parent().find('.market-selection-tag-content').css('display') === 'none';
    jQuery("#market-selection-tag > ul .market-selection-tag-content").slideUp();
    if (closed) {
      jQuery(this).parent().find('.market-selection-tag-content').slideToggle('fast');
    }
  });
}

function handleMarketTagPhraseKeyChange() {
  jQuery("#market-selection-tag > ul").on('change', '.market-selection-phrase-key', function (e) {
    jQuery(this).closest('li').find('.market-selection-tag-header').html(jQuery(this).val());
    formatMarketTag();
  });
}

function handleMarketTagMarketIdKeyChange() {
  jQuery("#market-selection-tag > ul").on('change', '.market-selection-tag-market-id', function (e) {
    formatMarketTag();
  });
}

function getMarketTagHtml(phraseKey, tags, marketId) {
  var title = phraseKey ? phraseKey : 'New Tag';
  praseKey = phraseKey ? phraseKey : '';
  marketId = marketId ? marketId : '';
  tags = tags ? tags : [];
  var html = '<li>';

  html += `<div class="market-selection-tag-header" style="padding: 10px; background: #f8f8f8; border: 1px solid #eee;">${title}<span style="float: right">${marketId}</span></div>`;
  html += '<div class="market-selection-tag-content" style="display: none; color: #FB3; background-color: #444; padding: 10px; border: 1px solid #eee;">';

  html += '<div class="form-group">';
  html += '<label>Tag Name:</label>';
  html += '<input type="text" class="market-selection-phrase-key form-control" value="' + praseKey + '">';
  html += '<label>Game Tamplate ID:</label>';
  html += '<input type="text" class="market-selection-tag-market-id form-control" value="' + marketId + '">';
  html += '</div>';
  html += '<div class="market-selection-tag-tags">';
  html += '<label>Selections index</label>'
  tags.forEach(function (tag) {
    html += getMarketTagSelection(
      tag
    );
  });
  html += '</div>';
  html += '<div class="form-group mt-3">';
  html += '<button type="button" class="btn btn-default add-market-selection-tag-tags">Add selection index</button>';
  html += '</div>';
  html += '<div class="form-group">';
  html += '<button type="button" class="btn btn-danger remove-market-selection-tag margin-right10" style="background-color: #C43D2F">Remove tag</button>';
  html += '<button type="button" class="btn btn-default copy-market-selection-tag">Copy tag</button>';
  html += '</div>';

  html += '</div>';
  html += '</li>';

  return html;
}

function getMarketTagSelection(tag) {
  const tagTopColor = tag.top === 'true' ? 'green' : 'grey';
  const tagNewColor = tag.new === 'true' ? 'green' : 'grey';
  const topValue = tag.top === 'true' ? 'true' : 'false';
  const newValue =tag.new === 'true' ? 'true' : 'false';
  var html = '<div class="row market-selection-tag-game margin-top15" id="test">';
  html += '<div class="col-md-9">';
  html += '<input type="number" name="selection_position" id="selection_position" required class="form-control market-selection-tag-3" placeholder="Selection position" value="' + tag.selection_position + '">';
  html += '</div>';
  html += '<div class="col-md-3">';
  html += '<button type="button" style="background-color: ' + tagTopColor + '; margin-right: 10px" class="top-market-selection-tag btn btn-default" value="' + topValue + '">TOP</button>';
  html += '<button type="button" style="background-color: ' + tagNewColor + '; margin-right: 10px" class="new-market-selection-tag btn btn-default" value="' + newValue + '">NEW</button>';
  html += '<button type="button" style="background-color: #FB3" class="remove-market-selection-tag-tags btn btn-default">Remove</button>';
  html += '</div>';
  html += '</div>';

  return html;
}

function handleAddMarketTagSelection() {
  jQuery("#market-selection-tag > ul").on('click', '.add-market-selection-tag-tags', function (e) {
    jQuery(this).parent().parent().find(".market-selection-tag-tags").append(getMarketTagSelection(''));
    setSortableOnMarketTagSelection();
    formatMarketTag();
  });
}

function handleCopyMarketTagSelection() {
  jQuery("#market-selection-tag > ul").on('click', '.copy-market-selection-tag', function (e) {
    jQuery(this).closest("li").clone().appendTo("#market-selection-tag ul");
    formatMarketTag();
  });
}

function handleRemoveMarketTag() {
  jQuery("#market-selection-tag > ul").on('click', '.remove-market-selection-tag', function (e) {
    jQuery(this).closest('li').remove();
    formatMarketTag();
  });
}

function handleRemoveMarketTagSelection() {
  jQuery("#market-selection-tag > ul").on('click', '.remove-market-selection-tag-tags', function (e) {
    jQuery(this).closest('.market-selection-tag-game').remove();
    formatMarketTag();
  });
}

function handleTopMarketTagSelection() {
  jQuery("#market-selection-tag > ul").on('click', '.top-market-selection-tag', function (e) {
    const current = jQuery(this).closest('.market-selection-tag-game').find('.top-market-selection-tag').val();
    const value = current === 'true' ? 'false' : 'true';
    jQuery(this).closest('.market-selection-tag-game').find('.new-market-selection-tag').attr('value', 'false')
    jQuery(this).closest('.market-selection-tag-game').find('.top-market-selection-tag').attr('value', value)
    const color = value === 'true' ? 'green' : 'grey';
    jQuery(this).closest('.market-selection-tag-game').find('.top-market-selection-tag').css("background-color", color);
    jQuery(this).closest('.market-selection-tag-game').find('.new-market-selection-tag').css("background-color", 'grey');
    formatMarketTag();
  });
}
function handleNewMarketTagSelection() {
  jQuery("#market-selection-tag > ul").on('click', '.new-market-selection-tag', function (e) {
    const current = jQuery(this).closest('.market-selection-tag-game').find('.new-market-selection-tag').val();
    const value = current === 'true' ? 'false' : 'true';
    jQuery(this).closest('.market-selection-tag-game').find('.top-market-selection-tag').attr('value', 'false')
    jQuery(this).closest('.market-selection-tag-game').find('.new-market-selection-tag').attr('value', value)

    const color = value === 'true' ? 'green' : 'grey';
    jQuery(this).closest('.market-selection-tag-game').find('.new-market-selection-tag').css("background-color", color);
    jQuery(this).closest('.market-selection-tag-game').find('.top-market-selection-tag').css("background-color", 'grey');

    formatMarketTag();
  });
}


function handleFormatMarketTag() {
  jQuery(".format-game-tooltip").click(function () {
    formatMarketTag();
  });
}

function formatMarketTag() {
  var result = [];

  jQuery("#market-selection-tag > ul li").each(function () {
    result.push({
      'phrase_key': jQuery(this).find('.market-selection-phrase-key').val(),
      'market_id': jQuery(this).find('.market-selection-tag-market-id').val(),
      'tags': formatMarketTagSelections(jQuery(this).find('.market-selection-tag-tags'))
    });
  });

  jQuery("#market-selection-tag-value").val(JSON.stringify(result));
}

function formatMarketTagSelections(element) {
  var result = [];

  element.find('.market-selection-tag-game').each(function () {
    result.push({
      'selection_position': jQuery(this).find('.market-selection-tag-3').val(),
      'top': jQuery(this).find('.top-market-selection-tag').val(),
      'new': jQuery(this).find('.new-market-selection-tag').val(),
    })
  });

  return result;
}

function formatMarketTagBeforeFormSubmit() {
  jQuery("form").submit(function (e) {
    formatMarketTag();
  });
}

initMarketTag();
