(function($) {

	var $websites = $('#websites').attr('value');

  if ($websites) {
    $websites = JSON.parse($websites);

    var currentWebsite = 0;

    function callAjax(website) {
      $.ajax({
        url : website.websockets_server_url + '/status/rooms',
          type: 'GET',
          data: null,
          timeout: 10000,
          processData: false,
          success: function(response, textStatus, xhr) {
            printDataAndCheckNextWebsite(response, xhr, website);
          },
          error: function(error, textStatus, xhr) {
            printDataAndCheckNextWebsite(error, xhr, website);
          }
      });
    }

    function check() {
      $websites.forEach(function(website) {
        callAjax(website);
      })
    }

    function printDataAndCheckNextWebsite(response, xhr, website) {
      var obj = checkStatus(response, xhr.status, website);
      var selector = $('#website-status-tr-'+website.id);

      printData(selector, obj);

      setTimeout(function() {
        callAjax(website);
      }, 10000);
    }

    if($('#websites-rooms-table').length === 1) {
      check();  
    }
    
    function checkStatus(response, statusCode, website) {
      var obj = {};

      var roomsList = [];
      var keys = Object.keys(response);

      keys.forEach(function(key) {

        if(!key.startsWith('/#') && !key.endsWith('message') && !key.endsWith('destroy')) {
          roomsList.push({
            name: key,
            connections: !isNaN(response[key]) ? response[key] : 'Error with connection'
          })
        }
        
      });

      obj[website.name] = roomsList;

      return roomsList;
    }

    function statusSuccess(code) {
      return code === 200;
    }

    function printData(trSelector, data) {

      var parsedData = parseTable(data)

      trSelector.find('.room-name').html(parsedData);

    }

    function parseTable(data) {
      var list = '<div class="scroll-on-table"><table class="table"><tr><th class="text-center"> Room </th> <th class="text-center"> Connection </th></tr>';

      data.forEach(function(d) {
        list += '<tr><td class="room-name"> ' + d.name + ' </td><td class="connections"> ' + d.connections + ' </td></tr>';
      });
      
      list = list + '</table></div>';

      return list;
    }
  }

})(jQuery);