$("#sport-filter").change(function(){
  var $this = $(this);
  var sportId = $this.val();
  var url = $this.data('url');

  if (sportId > 0) {
    url += '/sports/' + sportId;
  }

  window.location = url;
});