(function($) {

	var $websites = $('#websites').attr('value');

  if ($websites) {
    $websites = JSON.parse($websites);

    var currentWebsite = 0;

    function callProcessingServerAjax(website) {
      $.ajax({
        url : website.websockets_server_url + '/status',
          type: 'GET',
          data: null,
          timeout: 10000,
          processData: false,
          beforeSend: function () {
            $('#website-status-tr-'+website.id).find('.processing-status').html('updating');
          },
          success: function(response, textStatus, xhr) {
            printDataAndCheckNextWebsite(response, xhr, website);
          },
          error: function(error, textStatus, xhr) {
            printDataAndCheckNextWebsite(error, xhr, website);
          }
      });
    }

    function callCommunicationServerAjax(website) {
      $.ajax({
        url : website.websockets_server_url,
          type: 'GET',
          data: null,
          timeout: 10000,
          processData: false,
          beforeSend: function () {
            $('#website-status-tr-'+website.id).find('.communication-status').html('updating');
          },
          success: function(response, textStatus, xhr) {
            $('#website-status-tr-'+website.id).find('.communication-status').html('online');
          },
          error: function(response, textStatus, xhr) { 
            $('#website-status-tr-'+website.id).find('.communication-status').html('offline');
          }
      });
    }

    function check() {
      $websites.forEach(function(website) {
        callProcessingServerAjax(website);
        // callCommunicationServerAjax(website);
      });
    }

    function printDataAndCheckNextWebsite(response, xhr, website) {
      var obj = checkStatus(response, xhr.status, website);
      var selector = $('#website-status-tr-'+website.id);

      printData(selector, obj);

      setTimeout(function() {
        callProcessingServerAjax(website);
        // callCommunicationServerAjax(website);
      }, 10000);
    }

    if ($('#websites-status-table').length === 1) {
      check();
    }

    function checkStatus(response, statusCode, website) {
      var obj = {};

      return obj = {
        websiteName: website.name,
        websocketsServerUrl: website.websockets_server_url,
        status: statusSuccess(statusCode) ? 'online' : 'offline',
        queueLengthStandard: statusSuccess(statusCode) ? response.standard.queueLength : '-',
        processedListStandard: statusSuccess(statusCode) ? response.standard.processedList : '-',
        queueLengthLive: statusSuccess(statusCode) ? response.live.queueLength : '-',
        processedListLive: statusSuccess(statusCode) ? response.live.processedList : '-',
        queueLengthKeno: statusSuccess(statusCode) ? response.keno.queueLength : '-',
        processedListKeno: statusSuccess(statusCode) ? response.keno.processedList : '-'
      };
    }

    function statusSuccess(code) {
      return code === 200;
    }

    function printData(trSelector, data) {
      trSelector.find('.processing-status').html(data.status);
      trSelector.find('.website-queue').html(data.queueLength);
      trSelector.find('.website-queue-standard').html(data.queueLengthStandard);
      trSelector.find('.website-queue-live').html(data.queueLengthLive);
      trSelector.find('.website-queue-keno').html(data.queueLengthKeno);
      trSelector.find('.website-process').html(data.processedList);
      trSelector.find('.website-process-standard').html(data.processedListStandard);
      trSelector.find('.website-process-live').html(data.processedListLive);
      trSelector.find('.website-process-keno').html(data.processedListKeno);
    }
  }

})(jQuery);
