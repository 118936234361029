var $body = $('body');

function presentImageInForm(input) {
  if (window.FileReader && window.File && window.FileList && window.Blob) {
    if (input.files && input.files[0]) {
      var reader = new FileReader();
      reader.onload = function(e) {
        var $box = $(input).closest('.image-text-option');
        $box.find('.thumbnail').html('<img src="'+e.target.result+'">');
        $box.find('.upload-image-name').val(input.files[0]['name']);
      }
      reader.readAsDataURL(input.files[0]);
    }
  }
}

$('#website-configuration-form').submit(function() {
  var $this = $(this);
  var form = new FormData($this[0]);
  $('#website-configuration-update-ok').css('display', 'none');
  $('#website-configuration-update-fail').css('display', 'none');
  $.ajax({
    url : $this.attr('action'),
    type: 'POST',
    data: form,
    processData: false,
    contentType: false,
    success: function(response) {
      $('#website-configuration-update-ok').css('display', 'block');
      $('html, body').animate({scrollTop:0}, 'slow');
    },
    error: function(error) {
      $errors = error.responseJSON.errors;
      errorsHtml = '';

      $.each($errors, function(key, value) {
        errorsHtml += '<li>' + value[0] + '</li>';
      });

      $('#website-configuration-update-fail').css('display', 'block');
      $('#website-configuration-update-fail').html(errorsHtml);
      $('html, body').animate({scrollTop:0}, 'slow');
    }
  });

  return false;
});

$(document).ready(function () {
  if ($('#configurations-is_subdomain_redirect_url_enabled-0').is(':checked')) {
    $('#form-group-subdomain_redirect_url').hide();
  }
})

$('#website-configuration-form #form-group-is_subdomain_redirect_url_enabled input[type$=radio]').on('change', function () {
  if ($(this).val() == 1) {
    $('#form-group-subdomain_redirect_url').show();
  } else {
    $('#form-group-subdomain_redirect_url').hide();
  }
})

$(document).ready(function() {
  if ($('#jackpot-dropdown-type').length) {
      $('#drop-down-jackpot-type').show();
    }
});

$('.image-text-option input[type="file"]').change(function() {
  presentImageInForm(this);
});

$('#game-order-list').on('click', '.remove-games-order', function() {
  if (confirm('Are you sure you want to remove this item?') === true ) {
    $(this).parent().remove();
    reindexGameOrderPositions();
  }
});

function reindexGameOrderPositions() {
  $('.game-order-position-value').each(function(index) {
    $(this).html(index + 1);
  });

  $('.games-order-values-input').each(function(index) {
    $(this).attr('name', 'game_ids_order['+index+'][values]');
  });

  $('.games-order-type-select').each(function(index) {
    $(this).attr('name', 'game_ids_order['+index+'][type]');
  });
}

$('.add-new-game-order').click(function() {
  addNewGameOrderElement();
});

function addNewGameOrderElement() {
  var clone = $('.game-order-for-cloning').clone();
  clone.css("display", "block");
  clone.removeClass("game-order-for-cloning");
  clone.appendTo('#game-order-list');
  reindexGameOrderPositions();
}

function showHideNoFundsText() {
  var displayValue = jQuery("#configurations-is_no_funds_text_enabled-1").prop('disabled')
                     || jQuery("#configurations-is_no_funds_text_enabled-0").is(':checked')
                     ? 'none'
                     : 'block';
  jQuery("#form-group-no_funds_text").css('display', displayValue);
}

if (jQuery("#configurations-is_no_funds_text_enabled-1").length === 1) {
  showHideNoFundsText();
  jQuery("#configurations-is_no_funds_text_enabled-1, #configurations-is_no_funds_text_enabled-0").change(function() {
    showHideNoFundsText();
  });
}

jQuery("#configurations-is_turbo_payout_enabled-1").click(function() {
  jQuery("#configurations-is_turbo_cash_enabled-0").trigger("click");
});

jQuery("#configurations-is_turbo_cash_enabled-1").click(function() {
  jQuery("#configurations-is_turbo_payout_enabled-0").trigger("click");
});

jQuery("#configurations-is_two_step_registration_enabled-1").click(function() {
  jQuery("#configurations-is_ultra_short_registration_enabled-0").trigger("click");
});

jQuery("#configurations-is_ultra_short_registration_enabled-1").click(function() {
  jQuery("#configurations-is_two_step_registration_enabled-0").trigger("click");
});

jQuery(".sortable-items").sortable({
  create: function(event, ui) {
    let id = '#' + $(this).attr('data-id');
    let order = $(id).val();
    let orderArray = order.split(',');

    for (var i = orderArray.length - 1; i >= 0; i--) {
      $(this).prepend(
        $(this).find("[data-id='" + orderArray[i] + "']")
      );
    }
  },
  update: function (event, ui) {
    let output = '';
    let id = '#' + $(this).attr('data-id');
    $(this).find('li').each(function (item) {
      output += output ? ',' + $(this).attr('data-id') : $(this).attr('data-id');
    });

    $(id).val(output);
  }
});
