// Based on form from it returns string which describes section for preselection and phrase search
var isEditMode = false;
var exceptions = [];

function getSectionFromForm() {
  var sectionString;
  var formActionUrl;
  $('form').each(function() {
    if (this.action.search('translations') === -1) {
      formActionUrl = this.action;
    }
  });
  if (!formActionUrl) {
    return false;
  }

  if (formActionUrl.search('game-group') !== -1) {
    if ($('[name="sport_id"]').find(":selected").text().toLowerCase().indexOf("racing") >= 0) {
      sectionString = 'racing_page';
    } else {
      sectionString = 'general';
    }
  } else if (formActionUrl.search('casino-categories')) {
    sectionString = 'casino_page';
  }

  return sectionString;
}

function loadTranslationCreateModal(e) {
  var findKey = 'phrase_key';

  if ($(e).parent().parent().hasClass('url_key_translation')) {
    findKey = 'url_key';
  }

  var phraseKey = $(`form #${findKey}`).val();
  var $website = $('[name="website_id"]').val();
  $.get(
    "/translations/create",
    null,
    function(response) {
      printInTranslationModal(response);
      openTranslationModal();
      exceptions = $('#form-errors').data('exceptions');
      exceptions = exceptions ? exceptions : [];
      $('form #word-key').val(phraseKey);
      var sectionString = getSectionFromForm();
      if (sectionString) {
          $('form #goto').val('noRefresh');
        sectionString = sectionString.replace('_page', '');
        if (findKey === 'url_key') {
          sectionString = 'url'; 
        }
        var selectedSection = $('form #section option')
          .filter(function () {
            return $(this).html().toLowerCase().indexOf(sectionString) >= 0;
          }).val();
        $('form #section').val(selectedSection);
      }
      if (sectionString === 'casino') {
        var valueFromInput = $(e).parent().parent().siblings('input').val();
        if (!!valueFromInput) {
          $('#word-key').val(valueFromInput);
        }
      }
      $('form .websiteIdOnTranslationModal').val($website);
    }
  );
}

function loadTranslationEditModal(id) {
  isEditMode = true;
  var $website = $('[name="website_id"]').val();
  $.get(
    "/translations/"+id+"/edit",
    null,
    function(response) {
      printInTranslationModal(response);
      openTranslationModal();
      exceptions = $('#form-errors').data('exceptions');
      exceptions = exceptions ? exceptions : [];
      if (getSectionFromForm()) {
        $('form #goto').val('noRefresh');
      }
      $('form .websiteIdOnTranslationModal').val($website);
    }
  );
}

function openTranslationModal() {
  $("#translation-modal").modal("show");
}

function closeExceptionTranslationModal() {
  $("#translation-exception-modal").modal("hide");
  $("#translation-modal").modal("show");
  $( ".modal" ).css( "overflow", "auto");
  $('.word-key-exception').remove();
}

function openTranslationExceptionModal() {
  var wordKey = $("#word-key").val();
  if (wordKey) {
    $("#translation-modal").modal("hide");
    $.get(
      "/exception-translations",
      null,
      function(response) {
        $("#translation-exception-modal").modal("show");
        $("#translation-exception-modal .modal-body").html(response);
        $("#translation-exception-modal #exception-key").append(`<div class="word-key-exception">${wordKey}</div>`);
        setListener();
      } 
    )
  } else {
    value = "Key field Is Required"
    errorsHtml = '<div class="alert alert-danger"><ul>';

    errorsHtml += '<li>' + value + '</li>';
    errorsHtml += '</ul></div>';
    $('body #translation-modal').animate({ scrollTop: 0 }, 'fast');
    $('#form-errors').html(errorsHtml);
  }
}

$(document).mouseup(function(e) 
{
  var container = $("#translation-modal");
  if (container.is(':visible')) {
    if (container.has(e.target).length === 0) 
    {
      location.reload(true);
    } else {
      errorsHtml = '<div><ul>';
      errorsHtml += '</ul></div>';
      $('#form-errors').html(errorsHtml);
    }
  }
});

$(document).mouseup(function(e) 
{
  var container = $("#translation-exception-modal");
  if (container.is(':visible')) {
    if (container.has(e.target).length === 0) 
    {
      location.reload(true);
    }
  }
});

function closeTranslationModal() {
  $("#translation-modal").modal("hide");
  
  if($('body #categoriesModal').hasClass('in')) {
    setTimeout(function() {
      $('body').addClass('modal-open');
    }, 400);
  }

  location.reload(true);
}

function printInTranslationModal(content) {
  $("#translation-modal .modal-body").html(content);
}

// Phrase input search
var phraseKeyInputDelay = (function(){
  var phraseKeyChangeTimer = 0;
  return function(callback, ms){
    clearTimeout (phraseKeyChangeTimer);
    phraseKeyChangeTimer = setTimeout(callback, ms);
  };
})();

var urlKeyInputDelay = (function(){
  var urlKeyChangeTimer = 0;
  return function(callback, ms){
    clearTimeout (urlKeyChangeTimer);
    urlKeyChangeTimer = setTimeout(callback, ms);
  };
})();

$('form #phrase_key').keyup(function() {
  var value = $(this).val().toLowerCase().replace(" ", "_");
  $(this).val(value);

  phraseKeyInputDelay(function() {
    var sectionPage = getSectionFromForm();
    $('.phrase_key_translation').first().load(`/translations/${sectionPage}.${value}`);
  }, 600);
});

$('form #phrase_key').trigger('keyup');

$('form #url_key').keyup(function() {
  var value = $(this).val().toLowerCase().replace(" ", "_");
  $(this).val(value);

  urlKeyInputDelay(function() {
    $('.url_key_translation').first().load(`/translations/url.${value}`);
  }, 600);
});

$('form #url_key').trigger('keyup');

// Post new translation
$('body').on('submit', '#create-translation-form', function(e) {
  var $this = $(this);
  var form = new FormData($this[0]);
  e.preventDefault();

  $.ajax({
    url : $this.attr('action'),
    type: 'POST',
    data: form,
    processData: false,
    contentType: false,
    success: function(response) {
      if (response !== 'reloadTrue') {
        $("#translation-modal").modal("hide");
        var message = '<div class="alert alert-success">Succesfuly created translations</div>';
        $('#translations-success').html(message);
        var sectionPage = getSectionFromForm();
        if (sectionPage) {
          if (response.section_id === '24') { // Url section on casino category
            $('body form #url_key').val(response.key);
            urlKeyInputDelay(function () {
              $('body form .url_key_translation').first().load(`/translations/url.${response.key}`);
              $('body').addClass('modal-open');
            }, 400);
          } else {
            $('body form #phrase_key').val(response.key);
            phraseKeyInputDelay(function () {
              $('body form .phrase_key_translation').first().load(`/translations/${sectionPage}.${response.key}`);
            }, 400);

            $('body').removeClass('modal-open');
            $(`.tab_name_translation_${response.key}`).first().load(`/translations/casino_page.${response.key}`);
          }
        }

      } else {
        var message = '<div class="alert alert-success">Succesfuly created translations</div>';
        $("#translation-modal").modal("hide");
        $('#translations-success').html(message);

        setTimeout(function () {
          window.location.reload();
        }, 400)
      }
    },
    error: function(data) {
      $errors = data.responseJSON;
      errorsHtml = '<div class="alert alert-danger"><ul>';

      $.each($errors, function(key, value) {
        errorsHtml += '<li>' + value[0] + '</li>';
      });
      errorsHtml += '</ul></di>';
      $('body #translation-modal').animate({ scrollTop: 0 }, 'fast');
      $('#form-errors').html(errorsHtml);
    }
  });
});

// Update translation
$('body').on('submit', '#update-translation-form', function(e) {
  var $this = $(this);
  var form = new FormData($this[0]);
  e.preventDefault();

  $.ajax({
    url : $this.attr('action'),
    type: 'POST',
    data: form,
    processData: false,
    contentType: false,
    success: function(response) {
      if (response !== 'reloadTrue') {
        $("#translation-modal").modal("hide");
        var message = '<div class="alert alert-success">Succesfuly updated translations</div>';
        $('#translations-success').html(message);
        var sectionPage = getSectionFromForm();
        if (sectionPage) {
          if ($('#update-translation-form #section').first().val() === '24') {  // Url key on casino category
            $('body form #url_key').val(response);
            urlKeyInputDelay(function () {
              $('body form .url_key_translation').first().load(`/translations/url.${response}`);
              $('body').addClass('modal-open');
            }, 400);
          } else {
            $('body form #phrase_key').val(response);
            phraseKeyInputDelay(function () {
              $('body form .phrase_key_translation').first().load(`/translations/${sectionPage}.${response}`);
            }, 400);
            $('body').removeClass('modal-open');
            $(`.tab_name_translation_${response}`).first().load(`/translations/casino_page.${response}`);
          }
        }
      } else {
        var message = '<div class="alert alert-success">Succesfuly updated translations</div>';
        $("#translation-modal").modal("hide");
        $('#translations-success').html(message);

        setTimeout(function () {
          window.location.reload();
        }, 400)

      }
    },
    error: function(data) {
      $errors = data.responseJSON;
      errorsHtml = '<div class="alert alert-danger"><ul>';

      $.each($errors, function(key, value) {
        errorsHtml += '<li>' + value[0] + '</li>';
      });
      errorsHtml += '</ul></di>';
      $('body #translation-modal').animate({ scrollTop: 0 }, 'fast');
      $('#form-errors').html(errorsHtml);
    }
  });
});

$("#section").change(function(){
  $(this).closest("form").submit();
});

$('#translation-modal').on('click', '.delete-exception', function(e) {
  var id = $(this).data('id');
  var index = exceptions.findIndex(e => e.id == id);

  if (index) {
    exceptions.splice(index, 1);
  }

  var token = $(this).data('token');
  e.stopPropagation();
  if (confirm('Are you sure?') === true) {
    var element = $(this).closest('.display-flex-align');
    
    $.ajax({
      url: '/exception-translations/' + id,
      type: 'delete',
      data: {_method: 'delete', _token :token},
      success: function() {
        element.remove();
      }
    });
  }
});

$('#translation-modal').on('click', '.delete-exception-fe', function(e) {
  e.stopPropagation();
  var languageId = $(this).data('language');
  var websiteId = $(this).data('website');

  if (confirm('Are you sure?') === true) {
    var element = $(this).closest('.display-flex-align');
    element.remove();
    var index = exceptions.findIndex(e => {
      e.language_id == languageId && e.website_id == websiteId;
    });

    if (index) {
      exceptions.splice(index, 1);
    }
  }
});

function appendException() {
  var websiteId = $('#selected-website').val();
  var languageId = $('#select-languages').val();
  var exception = $('#translation-exception').val();
  var websites = $('#select-boxes').data('websites');
  var websiteName = '';
  websites.forEach(w => {
    if (w.id == websiteId) {
      websiteName = w.url;
    }
  });
  
  var counter = 0;

  if (exceptions) {
    exceptions.forEach(ex => {
      if (ex.language_id == languageId && ex.website_id == websiteId) {
        alert('Exception already exist for selected language!');
        counter ++;
        closeExceptionTranslationModal();
      }
    });
  }

  if (counter > 0) {
    return;
  }
  exceptions.push({language_id: languageId, website_id: websiteId});
  
  var form = isEditMode ? 
    $('#update-translation-form').find("#edit-language-" + languageId) : 
    $('#create-translation-form').find("#" + languageId);
  
  form.append(`<div class="display-flex-align margin-top15">
                <p class="translation-exception-p">${websiteName}</p>
                <input class="form-control translation-exception-input" 
                        name="exception[${languageId}][${websiteId}]" 
                        value="${exception}">
                        <a id="delete-exc-fe" 
                          data-language="${languageId}"
                          data-website="${websiteId}"
                          class="delete-exception-fe btn btn-default"><i class="fa fa-trash"></i>
                        </a>
              </div>`);

  closeExceptionTranslationModal();
}

function showLoader() {
  $('.loader-wrapper').css('display', 'flex');
}

function setListener () {
  $('#selected-website').change(function() {
    var websiteId = $('#selected-website').val();
    var languageId = $('#select-languages').val();
    $('.loader-wrapper').css('display', 'flex');
    $("#translation-exception-modal .modal-body").remove();
    
    $.ajax({
      
      headers: { 'X-CSRF-Token': $('meta[name="_token"]').attr('content') },
      url : '/exception-translations',
      type: 'GET',
      data: {
        'selectedWebsiteId': websiteId,
        'selectedLanguageId': languageId,
      },
      success: function(response) {
        $("#modal-body-id").append(`<div class="modal-body"></div>`);
        $("#translation-exception-modal .modal-body").append(response);
        $('#selected-website').val(websiteId);
        $('.loader-wrapper').hide();
        setListener();
      },
      error: function(error) {
        $('.loader-wrapper').hide();
        $("#modal-body-id").append(`<div class="modal-body"></div>`);
        alert('No Seo rules for that website!')
        closeExceptionTranslationModal();
      }
    });
  });
}