function initGameTolltip() {
    if (!jQuery("#game-tooltip ul").length) {
      return;
    }

    parseGameTolltipsValue();
    formatGameTolltipsBeforeFormSubmit();
    handleAddGameTolltip();
    handleShowGameTolltipContent();
    handleGameTolltipPhraseKeyChange();
    handleGameMarketIdKeyChange();
    handleAddGameTolltipSelection();
    handleCopyGameTolltipSelection();
    handleRemoveGameTolltip();
    handleRemoveGameTolltipSelection();
    handleFormatGameTolltips();

    $("#game-tooltip > ul").sortable({
      update: function( event, ui ) { formatGameTolltips(); }
    });

    setSortableOnTolltipSelection();
  }

  function setSortableOnTolltipSelection() {
    $("#game-tooltip > ul .game-tooltip-tooltips").sortable({
      update: function( event, ui ) { formatGameTolltips(); }
    });
  }

  function parseGameTolltipsValue() {
    var rawValue = jQuery("#game-tooltip-value").val();
    if (!rawValue) {
      return;
    }
    var gameTolltips = JSON.parse(rawValue);

    if (!gameTolltips.length) {
      return;
    }

    gameTolltips.forEach(function (tooltip) {
      jQuery("#game-tooltip > ul").append(getGameTolltipHtml(tooltip.phrase_key, tooltip.tooltips, tooltip.market_id));
    });
  }

  function handleAddGameTolltip() {
    jQuery("#game-tooltip .add-game-tooltip").click(function (e) {
      jQuery("#game-tooltip > ul").append(getGameTolltipHtml(null, null, null));
      formatGameTolltips();
    });
  }

  function handleShowGameTolltipContent() {
    jQuery("#game-tooltip > ul").on('click', '.game-tooltip-header', function (e) {
      var closed = jQuery(this).parent().find('.game-tooltip-content').css('display') === 'none';
      jQuery("#game-tooltip > ul .game-tooltip-content").slideUp();
      if (closed) {
        jQuery(this).parent().find('.game-tooltip-content').slideToggle('fast');
      }
    });
  }

  function handleGameTolltipPhraseKeyChange() {
    jQuery("#game-tooltip > ul").on('change', '.game-tooltip-phrase-key', function (e) {
      jQuery(this).closest('li').find('.game-tooltip-header').html(jQuery(this).val());
      formatGameTolltips();
    });
  }

  function handleGameMarketIdKeyChange() {
    jQuery("#game-tooltip > ul").on('change', '.game-tooltip-market-id', function (e) {
      formatGameTolltips();
    });
  }

  function getGameTolltipHtml(phraseKey, tooltips, marketId) {
    var title = phraseKey ? phraseKey : 'New Tolltip';
    praseKey = phraseKey ? phraseKey : '';
    marketId = marketId ? marketId : '';
    tooltips = tooltips ? tooltips : [];
    var html = '<li>';

    html += `<div class="game-tooltip-header" style="padding: 10px; background: #f8f8f8; border: 1px solid #eee;">${title}<span style="float: right">${marketId}</span></div>`;
    html += '<div class="game-tooltip-content" style="display: none; color: #FB3; background-color: #444; padding: 10px; border: 1px solid #eee;">';

    html += '<div class="form-group">';
    html += '<label>Translation phrase key:</label>';
    html += '<input type="text" class="game-tooltip-phrase-key form-control" value="' + praseKey + '">';
    html += '<label>Market ID:</label>';
    html += '<input type="text" class="game-tooltip-market-id form-control" value="' + marketId + '">';
    html += '</div>';
    html += '<div class="game-tooltip-tooltips">';
    html += '<label>Selections tooltips</label>'
    tooltips.forEach(function (tooltip) {
      html += getGameTolltipSelection(
        tooltip.selection_position
      );
    });
    html += '</div>';
    html += '<div class="form-group mt-3">';
    html += '<button type="button" class="btn btn-default add-game-tooltip-tooltip">Add selection tooltip</button>';
    html += '</div>';
    html += '<div class="form-group">';
    html += '<button type="button" class="btn btn-danger remove-game-tooltip margin-right10" style="background-color: #C43D2F">Remove tooltip</button>';
    html += '<button type="button" class="btn btn-default copy-game-tooltip">Copy tooltip</button>';
    html += '</div>';

    html += '</div>';
    html += '</li>';

    return html;
  }

  function getGameTolltipSelection(selection_position) {
    var html = '<div class="row game-tooltip-tooltip margin-top15" id="test">';
    html += '<div class="col-md-9">';
    html += '<input type="number" name="selection_position" id="selection_position" required class="form-control game-tooltip-tooltip-3" placeholder="Selection position" value="' + selection_position + '">';
    html += '</div>';
    html += '<div class="col-md-3">';
    html += '<button type="button" style="background-color: #FB3" class="remove-game-tooltip-tooltip btn btn-default">Remove</button>';
    html += '</div>';
    html += '</div>';

    return html;
  }

  function handleAddGameTolltipSelection() {
    jQuery("#game-tooltip > ul").on('click', '.add-game-tooltip-tooltip', function (e) {
      jQuery(this).parent().parent().find(".game-tooltip-tooltips").append(getGameTolltipSelection(''));
      setSortableOnTolltipSelection();
      formatGameTolltips();
    });
  }

  function handleCopyGameTolltipSelection() {
    jQuery("#game-tooltip > ul").on('click', '.copy-game-tooltip', function (e) {
      jQuery(this).closest("li").clone().appendTo("#game-tooltip ul");
      formatGameTolltips();
    });
  }

  function handleRemoveGameTolltip() {
    jQuery("#game-tooltip > ul").on('click', '.remove-game-tooltip', function (e) {
      jQuery(this).closest('li').remove();
      formatGameTolltips();
    });
  }

  function handleRemoveGameTolltipSelection() {
    jQuery("#game-tooltip > ul").on('click', '.remove-game-tooltip-tooltip', function (e) {
      jQuery(this).closest('.game-tooltip-tooltip').remove();
      formatGameTolltips();
    });
  }

  function handleFormatGameTolltips() {
    jQuery(".format-game-tooltip").click(function () {
      formatGameTolltips();
    });
  }

  function formatGameTolltips() {
    var result = [];

    jQuery("#game-tooltip > ul li").each(function () {
      result.push({
        'phrase_key': jQuery(this).find('.game-tooltip-phrase-key').val(),
        'market_id': jQuery(this).find('.game-tooltip-market-id').val(),
        'tooltips': formatGameTolltipSelections(jQuery(this).find('.game-tooltip-tooltips'))
      });
    });

    jQuery("#game-tooltip-value").val(JSON.stringify(result));
  }

  function formatGameTolltipSelections(element) {
    var result = [];

    element.find('.game-tooltip-tooltip').each(function () {
      result.push({
        'selection_position': jQuery(this).find('.game-tooltip-tooltip-3').val(),
      })
    });

    return result;
  }

  function formatGameTolltipsBeforeFormSubmit() {
    jQuery("form").submit(function (e) {
      formatGameTolltips();
    });
  }

  initGameTolltip();
