function openLivePageOrderModal() {
  $("#live-page-order-modal").modal("show");
}

function closeLivePageOrderModal() {
  $("#live-page-order-modal").modal("hide");
}

function printInLiveOrderSportModal(what) {
  $("#live-page-order-modal .modal-body").html(what);
}

function loadSportLiveOrderCreateModal(websiteId) {
  $.get(`/websites/${websiteId}/live-config/create`, null, function(response) {
    printInLiveOrderSportModal(response);
    openSportModal();
  });
  openLivePageOrderModal();
}

function openStandardPageOrderModal() {
  $("#standard-page-order-modal").modal("show");
}

function closeStandardPageOrderModal() {
  $("#standard-page-order-modal").modal("hide");
}

function printInStandardOrderSportModal(what) {
  $("#standard-page-order-modal .modal-body").html(what);
}

function loadSportStandardOrderCreateModal(websiteId) {
  $.get(`/websites/${websiteId}/standard-config/create`, null, function(response) {
    printInStandardOrderSportModal(response);
    openSportModal();
  });
  openStandardPageOrderModal();
}

function showInputField(section, sportId, key) {
  $(`#${section}-add-input-${sportId}-${key}`)[0].className = "c-tag c-tag--edit";
  $(`#${section}-plus-button-${sportId}-${key}`)[0].className = "hidden";
  $(`#${section}-add-input-${sportId}-${key} input`).focus();
}

function hideInputField(section, sportId, key) {
  if(!$(`#${section}-add-input-${sportId}-${key}`)[0]) {
    return;
  }
  $(`#${section}-add-input-${sportId}-${key}`)[0].className = "c-tag c-tag--edit hidden";
  $(`#${section}-addField-${sportId}-${key}`)[0].value = ''
  $(`#${section}-plus-button-${sportId}-${key}`)[0].className = "c-tag c-tag--add c-tag--table-hidden";
}

function changeToEdit(section, sportId, key, selectionPosition) {
  $(`#${section}-tag-${sportId}-${key}-${selectionPosition}`)[0].className = "c-tag c-tag--edit";
  $(`#${section}-remove-button-${sportId}-${key}-${selectionPosition}`)[0].className = "hidden";
  $(`#${section}-edit-button-${sportId}-${key}-${selectionPosition}`)[0].className = "hidden";
  $(`#${section}-cancel-button-${sportId}-${key}-${selectionPosition}`)[0].className = "c-tag__remove fa fa-times";
  $(`#${section}-add-edit-${sportId}-${key}-${selectionPosition}`)[0].className = "c-tag__add fa fa-check";
}

function cancelEdit(section, sportId, key, selectionPosition) {
  $(`#${section}-tag-${sportId}-${key}-${selectionPosition}`)[0].className = "c-tag";
  $(`#${section}-remove-button-${sportId}-${key}-${selectionPosition}`)[0].className = "c-tag__remove fa fa-trash";
  $(`#${section}-edit-button-${sportId}-${key}-${selectionPosition}`)[0].className = "c-tag__edit fa fa-edit";
  $(`#${section}-cancel-button-${sportId}-${key}-${selectionPosition}`)[0].className = "hidden";
  $(`#${section}-add-edit-${sportId}-${key}-${selectionPosition}`)[0].className = "hidden";
  $(`#${section}-input-${sportId}-${key}-${selectionPosition}`).val($(`#${section}-input-${sportId}-${key}-${selectionPosition}`).data('defaultvalue'));
}

function changeToEditSpecialGameTranslation(section, sportId, selectionPosition) {
  $(`#${section}-special-game-translation-wrapper-${sportId}-${selectionPosition}`)[0].className = 'c-tag c-tag--translations is-editing';
}

function cancelEditingSpecialGameTranslation(section, sportId, selectionPosition) {
  setTimeout(() => {
    $(`#${section}-special-game-translation-wrapper-${sportId}-${selectionPosition}`)[0].className = 'c-tag c-tag--translations';
    $(`#${section}-special-game-translation-${sportId}-${selectionPosition}`).val($(`#${section}-special-game-translation-${sportId}-${selectionPosition}`).data('defaultvalue'));
  },300)
}

function changeToEditMarketTranslation(section, sportId, selectionPosition) {
  $(`#${section}-market-translation-wrapper-${sportId}-${selectionPosition}`)[0].className = 'c-tag c-tag--translations is-editing';
}

function cancelEditingMarketTranslation(section, sportId, selectionPosition) {
  setTimeout(() => {
    $(`#${section}-market-translation-wrapper-${sportId}-${selectionPosition}`)[0].className = 'c-tag c-tag--translations';
    $(`#${section}-market-translation-${sportId}-${selectionPosition}`).val($(`#${section}-market-translation-${sportId}-${selectionPosition}`).data('defaultvalue'));
  },300)
}

function changeToEditNumberOfTranslation(section, sportId, selectionPosition) {
  $(`#${section}-number-of-selections-wrapper-${sportId}-${selectionPosition}`)[0].className = 'c-tag c-tag--translations is-editing';
}

function cancelEditingNumberOfTranslation(section, sportId, selectionPosition) {
  setTimeout(() => {
    $(`#${section}-number-of-selections-wrapper-${sportId}-${selectionPosition}`)[0].className = 'c-tag c-tag--translations';
    $(`#${section}-number-of-selections-${sportId}-${selectionPosition}`).val($(`#${section}-number-of-selections-${sportId}-${selectionPosition}`).data('defaultvalue'));
  },300)
}

function saveFixedOU(selectedSection, sportId, sectionId, gamesOrder, websiteId, isLive, selectionPosition) {
  const numberOfSelectionForMarket = $(`#${selectedSection}-ou-${sportId}-${selectionPosition}`)[0].value;
  const gamesIdsOrder = JSON.parse(gamesOrder);
  gamesIdsOrder[selectedSection][selectionPosition].ou = numberOfSelectionForMarket || 2.5;

  const successMessage = 'Fixed OU for market updated'
  this.updateSportIds(websiteId, sectionId, gamesIdsOrder, sportId, selectedSection, successMessage, isLive, selectionPosition);
}

function changeToEditFixedOU(section, sportId, selectionPosition) {
  $(`#${section}-ou-wrapper-${sportId}-${selectionPosition}`)[0].className = 'c-tag c-tag-translations is-editing';
}

function cancelEditingFixedOU(section, sportId, selectionPosition) {
  setTimeout(() => {
    $(`#${section}-ou-wrapper-${sportId}-${selectionPosition}`)[0].className = 'c-tag c-tag-translations';
    $(`#${section}-ou-${sportId}-${selectionPosition}`).val($(`#${section}-ou-${sportId}-${selectionPosition}`).data('defaultvalue'));
  }, 300)
}

function addNewSelections(sectionName, sportId, selectionId, gamesOrder, websiteId, isLive) {
  const gamesIdsOrder = JSON.parse(gamesOrder);
  const successMessage = 'Added new section'
  gamesIdsOrder[sectionName].push({"specialGameTranslationkey":'', 'marketTranslationKey': '', 'numberOfSelectionForMarket': '', 'rule': 'static_value', 'value': []})
  this.updateSportIds(websiteId, selectionId, gamesIdsOrder, sportId, sectionName, successMessage, isLive);
}

function redirectToSingleSport(websiteId) {
  const sportId = $(`#live-page-order`)[0].value;
  if(sportId == 0) {
    window.location.href = `/websites/${websiteId}/live-config`
    return;
  }
  window.location.href = `/websites/${websiteId}/live-config/${sportId}`
}

function redirectToSingleSportStandard(websiteId) {
  const sportId = $(`#standard-page-order`)[0].value;
  if(sportId == 0) {
    window.location.href = `/websites/${websiteId}/standard-config`
    return;
  }
  window.location.href = `/websites/${websiteId}/standard-config/${sportId}`
}

function editMarketAndSave(section, sportId, selectionId, gamesOrder, key, websiteId, isLive, selectionPosition) {
  const newValue =  $(`#${section}-input-${sportId}-${key}-${selectionPosition}`)[0].value
  const gamesIdsOrder = JSON.parse(gamesOrder);
  let oldValue = gamesIdsOrder[section][selectionPosition].value[key];
  if(newValue === oldValue) {
    errorMessage('Template id is same as old one');
    return;
  }
  if(gamesIdsOrder[section][selectionPosition].value.includes(newValue)) {
    errorMessage('This Template id already exists');
    return;
  }
  gamesIdsOrder[section][selectionPosition].value[key] = newValue
  const successMessage = 'Successfully edited template id'
  updateSportIds(websiteId, selectionId, gamesIdsOrder, sportId, section, successMessage, isLive, selectionPosition);
  cancelEdit(section, sportId, key, selectionPosition)
}

function selectDefaultMarketValue(selectedSection, sportId, sectionId, gamesOrder, websiteId, isLive, selectionPosition) {
  const selectedMarketType =  $(`#${selectedSection}-selected-value-${sportId}-${selectionPosition}`)[0].value;
  const gamesIdsOrder = JSON.parse(gamesOrder);

  if (selectedMarketType === 'fixed_ou') {
    gamesIdsOrder[selectedSection][selectionPosition]['ou'] = '2.5';
  } else {
    gamesIdsOrder[selectedSection][selectionPosition]['ou'] = null;
  }

  if(isRuleChanged(gamesIdsOrder[selectedSection][selectionPosition].rule, selectedMarketType)) {
    gamesIdsOrder[selectedSection][selectionPosition]['value'] = [];
  }

  gamesIdsOrder[selectedSection][selectionPosition]['rule'] = selectedMarketType;
  const successMessage = 'Successfully changed market type'
  updateSportIds(websiteId, sectionId, gamesIdsOrder, sportId, selectedSection, successMessage, isLive, selectionPosition);
}

function selectDefaultNumberOfSelectionValue(sportId, sectionId, websiteId, isLive) {
  let selectedNumberOfSelection = $(`#selected-sport-number-of-selections-${sportId}`)[0].value;

  $.ajax({
    headers: { "X-CSRF-Token": $('meta[name="_token"]').attr("content") },
    url: `/websites/${websiteId}/${isLive == '1' ? 'live' : 'standard'}-config/${sectionId}/update-selections-number`,
    type: "POST",
    dataType: "json",
    data: JSON.stringify({ selectedNumberOfSelection }),
    contentType: "application/json; charset=utf-8",
    success: function(response) {
     $(`#list-of-sections-${sportId}`).html(response.html);
    },
    error: function(data) {
      if (data.status !== 200) {
        errorMessage('Something went wrong');

        return;
      }
      successMessage("Successfully changed number of selections")
    }
  });
}

function addSelectedMarketValue(
  selectedSection,
  sportId,
  sectionId,
  gamesOrder,
  websiteId,
  isLive,
  selectionPosition
) {
  const selectedValue = $(`#${selectedSection}-addField-${sportId}-${selectionPosition}`)[0].value;
  
  if (!selectedValue) {
    return;
  }

  const gamesIdsOrder = JSON.parse(gamesOrder);

  if(gamesIdsOrder[selectedSection][selectionPosition].value.includes(selectedValue)) {
    errorMessage('This Template id already exists');
    return;
  }

  const successMessage = 'Added new section'
  gamesIdsOrder[selectedSection][selectionPosition].value.push(selectedValue)
  this.updateSportIds(websiteId, sectionId, gamesIdsOrder, sportId, selectedSection, successMessage, isLive, selectionPosition);
}

function saveSpecialGameTranslations(selectedSection, sportId, sectionId, gamesOrder, websiteId, isLive, selectionPosition) {
  const specialGameTranslationkey = $(`#${selectedSection}-special-game-translation-${sportId}-${selectionPosition}`)[0].value;
  const gamesIdsOrder = JSON.parse(gamesOrder);
  gamesIdsOrder[selectedSection][selectionPosition].specialGameTranslationkey = specialGameTranslationkey;
  const successMessage = 'Special game translation updated'
  this.updateSportIds(websiteId, sectionId, gamesIdsOrder, sportId, selectedSection, successMessage, isLive, selectionPosition);
}

function saveMarketTranslations(selectedSection, sportId, sectionId, gamesOrder, websiteId, isLive, selectionPosition) {
  const marketTranslationKey =  $(`#${selectedSection}-market-translation-${sportId}-${selectionPosition}`)[0].value;
  const gamesIdsOrder = JSON.parse(gamesOrder);
  gamesIdsOrder[selectedSection][selectionPosition].marketTranslationKey = marketTranslationKey;
  const successMessage = 'Market translation updated'
  this.updateSportIds(websiteId, sectionId, gamesIdsOrder, sportId, selectedSection, successMessage, isLive, selectionPosition);
}

function saveNumberOfSelections(selectedSection, sportId, sectionId, gamesOrder, websiteId, isLive, selectionPosition) {
  const numberOfSelectionForMarket = $(`#${selectedSection}-number-of-selections-${sportId}-${selectionPosition}`)[0].value;
  const gamesIdsOrder = JSON.parse(gamesOrder);
  gamesIdsOrder[selectedSection][selectionPosition].numberOfSelectionForMarket = numberOfSelectionForMarket;
  const successMessage = 'Number of translation for market updated'
  this.updateSportIds(websiteId, sectionId, gamesIdsOrder, sportId, selectedSection, successMessage, isLive, selectionPosition);
}

function updateSportIds(websiteId, sectionId, payload, sportId, selectedSection, message = '', isLive = '0', selectionPosition = false) {
  $.ajax({
    headers: { "X-CSRF-Token": $('meta[name="_token"]').attr("content") },
    url: `/websites/${websiteId}/${isLive == '1' ? 'live' : 'standard'}-config/${sectionId}`,
    type: "PATCH",
    dataType: "json",
    data: JSON.stringify({ payload, selectedSection }),
    contentType: "application/json; charset=utf-8",
    success: function(response) {
     $(`#list-of-sections-${sportId}`).html(response.html);
     if(selectionPosition) {
      hideInputField(selectedSection, sportId, selectionPosition)
     }
     successMessage(message)
    },
    error: function(data) {
      errorMessage('Something went wrong');
    }
  });
}

function removeSelections(sectionName, sportId, selectionId, gamesOrder, websiteId, isLive, $key) {
  if(!confirm('Are you sure you want to delete this section?')) {
    return;
  }
  const gamesIdsOrder = JSON.parse(gamesOrder);

  gamesIdsOrder[sectionName].splice($key, 1);
  const successMessage = 'Removed section';
  this.updateSportIds(websiteId, selectionId, gamesIdsOrder, sportId, sectionName, successMessage, isLive);
}

function saveStaticMixRule(sectionName, sportId, selectionId, gamesOrder, websiteId, isLive, key) {
  const gamesIdsOrder = JSON.parse(gamesOrder); 

  const dataForSaving = [];
  for(let j = 1; j <= 4; j++) { 
    const gameTemplate =  $(`#${sectionName}-input-static-${sportId}-${j}-1-${key}`)[0].value;
    const position =  $(`#${sectionName}-input-static-${sportId}-${j}-2-${key}`)[0].value;
    const translationKey =  $(`#${sectionName}-input-static-${sportId}-${j}-3-${key}`)[0].value;
    dataForSaving.push({gameTemplate, position, translationKey})
  }

  const successMessage = 'Static mix updated'

  gamesIdsOrder[sectionName][key].value = dataForSaving;
  this.updateSportIds(websiteId, selectionId, gamesIdsOrder, sportId, sectionName, successMessage, isLive);
}


function removeSelectedMarketId(sectionName, sportId, selectionId, gamesOrder, marketForDeleting, websiteId, isLive, selectionPosition) {
  if(!confirm('Are you sure you want to delete template id?')) {
    return;
  }

  const gamesIdsOrder = JSON.parse(gamesOrder); 
  gamesIdsOrder[sectionName][selectionPosition].value = gamesIdsOrder[sectionName][selectionPosition].value.filter(number => number !== marketForDeleting);
  const successMessage = 'Successfully removed template id'
  updateSportIds(websiteId, selectionId, gamesIdsOrder, sportId, sectionName, successMessage, isLive, selectionPosition) 
}

function successMessage(response) {
  const message = `<div class="alert alert-success c-snackbar__message">${response}</div>`;
  $('#successMessage').html(message);
  setTimeout(function() {$('#successMessage').html('')}, 2000);
}

function errorMessage(response) {
  const message = `<div class="alert alert-danger c-snackbar__message c-snackbar__message--error">${response}</div>`;
  $('#successMessage').html(message);
  setTimeout(function() {$('#successMessage').html('')}, 3000);
}

function confirmation(sectionId){
  let form = $(`#delete-language-form-${sectionId}`);
  bootbox.dialog({
    message: "Are you sure you want to delete this section?",
    title: 'Are you sure?',
    buttons: {
      success: {
        label: 'Cancel',
        className: 'btn-default pull-left',
        callback: function() {
          bootbox.hideAll();
        }
      },
      danger: {
        label: 'Yes',
        className: 'btn-danger',
        callback: function() {
          form.submit();
        }
      }
    },
    onEscape: function() {
      bootbox.hideAll();
    }
  });
}

function moveItem(sectionName, sportId, selectionId, gamesOrder, websiteId, isLive, key, isMovingUp = false) {
  const moveUp = isMovingUp ? Number(key) - 1 : Number(key) + 1;
  const gamesIdsOrder = JSON.parse(gamesOrder); 
  let newValue = gamesIdsOrder[sectionName][moveUp];
  gamesIdsOrder[sectionName][moveUp] = gamesIdsOrder[sectionName][key];
  gamesIdsOrder[sectionName][key] = newValue;
  const successMessage = 'Order change'

  this.updateSportIds(websiteId, selectionId, gamesIdsOrder, sportId, sectionName, successMessage, isLive);
}