$('.generate-action-link').click(function() {
    var actionLink = '';
    $('.add-to-action-link:checked').each(function() {
        actionLink +=
            actionLink != '' ?
            '-' + $(this).attr('data-id') :
            $(this).attr('data-id');
    });
    actionLink = actionLink != '' ? '[Website URL]/[Language ISO2 Code]/[Page name]?ticket-action=' + actionLink : actionLink;
    $('.action-link-textarea').val(actionLink);
});