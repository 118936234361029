function openSportModal() {
    $("#sport-modal").modal("show");
  }
  
  function closeSportModal() {
    $("#sport-modal").modal("hide");
  }
  
  function printInSportModal(what) {
    $("#sport-modal .modal-body").html(what);
  }
  
  function loadSportCreateModal() {
    $.get(
      "sports/create",
      null,
      function(response) {
        printInSportModal(response);
        openSportModal();
      }
    );
  }
  
  function loadSportEditModal(id) {
    $.get(
      "sports/" + id + "/edit",
      null,
      function(response) {
        printInSportModal(response);
        openSportModal();
      }
    );
  }
  
  $("#add-new-sport-btn").click(function(e) {
    e.preventDefault();
    loadSportCreateModal();
  });
  
  $(".edit-sport").click(function(e) {
    e.preventDefault();
    loadSportEditModal($(this).attr("data-id"));
  });
  
  $('body').on('submit', '#sport-form', function(e) {
      var $this = $(this);
      var form = new FormData($this[0]);
      e.preventDefault();
  
      $.ajax({
        url : $this.attr('action'),
        type: 'POST',
        data: form,
        processData: false,
        contentType: false,
        success: function(response) {
            closeSportModal();
            message = `<div class="alert alert-success">Successfully added new sport</div>`;
            $('#successMessage').html(message);
            location.reload(true);
        },
        error: function(data) {
          message = `<div class="alert alert-danger">${data.responseJSON.error}</div>`;
          $('#errorMessage').html(message);
        }
      });
  });
  