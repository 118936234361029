function initJackpotsPage() {
  if (!jQuery("#all-jackpots ul").length) {
    return;
  }
  
  formatJackpotsBeforeFormSubmit();
  handleShowJackpotContent();
  handleAddJackpot();
  handleJackpotPhraseKeyChange();
  parseJackpotsValue();
  handleJackpotSsidChange();
  handleRemoveJackpot();

  $("#all-jackpots > ul").sortable({
    update: function( event, ui ) { formatJackpots(); }
  });
  
}

function handleAddJackpot() {
  jQuery("#all-jackpots .add-jackpot").click(function (e) {
    jQuery("#all-jackpots > ul").append(getGetJackpotsHtml(null, null));
    formatJackpots();
  });
}

function getGetJackpotsHtml(phraseKey, ssid) {
  var title = phraseKey ? phraseKey : 'New Jackpot';
  praseKey = phraseKey ? phraseKey : '';
  ssid = ssid ? ssid : '';
  var html = '<li>';
  
  html += '<div class="jackpot-header" style="padding: 10px; background: #f8f8f8; border: 1px solid #eee;">' + title + '</div>';
  html += '<div class="jackpot-content" style="display: none; padding: 10px; border: 1px solid #eee;">';
  html += '<div class="form-group">';
  html += '<label>Jackpot name:</label>';
  html += '<input type="text" class="jackpot-phrase-key form-control" value="' + praseKey + '">';

  html += '<label>Jackpot SSID:</label>';
  html += '<input type="text" class="jackpot-ssid form-control" value="' + ssid + '">';

  html += '</div>';
  html += '<button type="button" class="btn btn-danger remove-jackpot margin-right10">Remove jackpot</button>';
  html += '</div>';
  
  html += '</div>';
  html += '</li>';

  return html;
}

function formatJackpots() {
  var result = [];

  jQuery("#all-jackpots > ul li").each(function () {
    result.push({
      'phrase_key': jQuery(this).find('.jackpot-phrase-key').val(),
      'jackpot_ssid': jQuery(this).find('.jackpot-ssid').val(),
    });
  });

  jQuery("#jackpots-value").val(JSON.stringify(result));
}

function handleShowJackpotContent() {
  jQuery("#all-jackpots > ul").on('click', '.jackpot-header', function (e) {
    var closed = jQuery(this).parent().find('.jackpot-content').css('display') === 'none';
    jQuery("#all-jackpots > ul .jackpot-content").slideUp();
    if (closed) {
      jQuery(this).parent().find('.jackpot-content').slideToggle('fast');
    }
  });
}

function handleJackpotPhraseKeyChange() {
  jQuery("#all-jackpots > ul").on('change', '.jackpot-phrase-key', function (e) {
    jQuery(this).closest('li').find('.jackpot-header').html(jQuery(this).val());
    formatJackpots();
  });
}

function handleJackpotSsidChange() {
  jQuery("#all-jackpots > ul").on('change', '.jackpot-ssid', function (e) {
    formatJackpots();
  });
}

function handleRemoveJackpot() {
  jQuery("#all-jackpots > ul").on('click', '.remove-jackpot', function (e) {
    jQuery(this).closest('li').remove();
    formatJackpots();
  });
}

function parseJackpotsValue() {
  var rawValue = jQuery("#jackpots-value").val();
  if (!rawValue) {
    return;
  }
  var allJackpots = JSON.parse(rawValue);

  if (!allJackpots.length) {
    return;
  }

  allJackpots.forEach(function (jackpot) {
    var parsedJackpot = JSON.parse(jackpot.jackpots);
    parsedJackpot.forEach(function (j) {
      jQuery("#all-jackpots > ul").append(getGetJackpotsHtml(j.phrase_key, j.jackpot_ssid));
    })
  });
}

function formatJackpotsBeforeFormSubmit() {
  jQuery("form").submit(function (e) {
    formatJackpots();
  });
}

initJackpotsPage()