function openLivePageOrderMobileModal() {
    $("#live-page-order-mobile-modal").modal("show");
  }
  
  function closeLivePageMobileOrderModal() {
    $("#live-page-order-mobile-modal").modal("hide");
  }
  
  function printInLiveOrderSportModalMobile(what) {
    $("#live-page-order-mobile-modal .modal-body").html(what);
  }
  
  function loadSportLiveOrderCreateModalMobile(websiteId) {
    $.get(`/websites/${websiteId}/live-config-mobile/create`, null, function(response) {
      printInLiveOrderSportModalMobile(response);
      openSportModal();
    });
    openLivePageOrderMobileModal();
  }

  function openStandardPageOrderMobileModal() {
    $("#standard-page-order-mobile-modal").modal("show");
  }
  
  function closeStandardPageMobileOrderModal() {
    $("#standard-page-order-mobile-modal").modal("hide");
  }
  
  function printInStandardOrderSportModalMobile(what) {
    $("#standard-page-order-mobile-modal .modal-body").html(what);
  }
  
  function loadSportStandardOrderCreateModalMobile(websiteId) {
    console.log("why")
    $.get(`/websites/${websiteId}/standard-config-mobile/create`, null, function(response) {
      printInStandardOrderSportModalMobile(response);
      openSportModal();
    });
    openStandardPageOrderMobileModal();
  }
  
  function showInputFieldMobile(section, sportId, key) {
    $(`#${section}-add-input-mobile-${sportId}-${key}`)[0].className = "c-tag c-tag--edit";
    $(`#${section}-plus-button-mobile-${sportId}-${key}`)[0].className = "hidden";
    $(`#${section}-add-input-mobile-${sportId}-${key} input`).focus();
  }
  
  function hideInputFieldMobile(section, sportId, key) {
    if(!$(`#${section}-add-input-mobile-${sportId}-${key}`)[0]) {
      return;
    }
    $(`#${section}-add-input-mobile-${sportId}-${key}`)[0].className = "c-tag c-tag--edit hidden";
    $(`#${section}-addField-mobile-${sportId}-${key}`)[0].value = ''
    $(`#${section}-plus-button-mobile-${sportId}-${key}`)[0].className = "c-tag c-tag--add c-tag--table-hidden";
  }
  
  function changeToEditMobile(section, sportId, key, selectionPosition) {
    $(`#${section}-tag-${sportId}-${key}-mobile-${selectionPosition}`)[0].className = "c-tag c-tag--edit";
    $(`#${section}-remove-button-mobile-${sportId}-${key}-${selectionPosition}`)[0].className = "hidden";
    $(`#${section}-edit-button-mobile-${sportId}-${key}-${selectionPosition}`)[0].className = "hidden";
    $(`#${section}-cancel-button-mobile-${sportId}-${key}-${selectionPosition}`)[0].className = "c-tag__remove fa fa-times";
    $(`#${section}-add-edit-mobile${sportId}-${key}-${selectionPosition}`)[0].className = "c-tag__add fa fa-check";
  }
  
  function cancelEditMobile(section, sportId, key, selectionPosition) { 
    $(`#${section}-tag-${sportId}-${key}-mobile-${selectionPosition}`)[0].className = "c-tag";
    $(`#${section}-remove-button-mobile-${sportId}-${key}-${selectionPosition}`)[0].className = "c-tag__remove fa fa-trash";
    $(`#${section}-edit-button-mobile-${sportId}-${key}-${selectionPosition}`)[0].className = "c-tag__edit fa fa-edit";
    $(`#${section}-cancel-button-mobile-${sportId}-${key}-${selectionPosition}`)[0].className = "hidden";
    $(`#${section}-add-edit-mobile${sportId}-${key}-${selectionPosition}`)[0].className = "hidden";
    $(`#${section}-input-mobile-${sportId}-${key}-${selectionPosition}`).val($(`#${section}-input-mobile-${sportId}-${key}-${selectionPosition}`).data('defaultvalue'));
  }
  
  function changeToEditMarketTranslationMobile(section, sportId, selectionPosition) {
    $(`#${section}-market-translation-mobile-wrapper-${sportId}-${selectionPosition}`)[0].className = 'c-tag c-tag--translations is-editing';
  }
  
  function changeToEditSpecialGameTranslationMobile(section, sportId,selectionPosition) {
    $(`#${section}-special-game-translation-mobile-wrapper-${sportId}-${selectionPosition}`)[0].className = 'c-tag c-tag--translations is-editing';
  }
  
  function cancelEditingMarketTranslationMobile(section, sportId, selectionPosition) {
    setTimeout(() => {
      $(`#${section}-market-translation-mobile-wrapper-${sportId}-${selectionPosition}`)[0].className = 'c-tag c-tag--translations';
      $(`#${section}-market-translation-mobile-${sportId}-${selectionPosition}`).val($(`#${section}-market-translation-mobile-${sportId}-${selectionPosition}`).data('defaultvalue'));
    },300)
  }
  
  function cancelEditingSpecialGameTranslationMobile(section, sportId, selectionPosition) {
    setTimeout(() => {
      $(`#${section}-special-game-translation-mobile-wrapper-${sportId}-${selectionPosition}`)[0].className = 'c-tag c-tag--translations';
      $(`#${section}-special-game-translation-mobile-${sportId}-${selectionPosition}`).val($(`#${section}-special-game-translation-mobile-${sportId}-${selectionPosition}`).data('defaultvalue'));
    },300)
  }
  
  function changeToEditNumberOfTranslationMobile(section, sportId, selectionPosition) {
    $(`#${section}-number-of-selections-mobile-wrapper-${sportId}-${selectionPosition}`)[0].className = 'c-tag c-tag--translations is-editing';
  }
  
  function cancelEditingNumberOfTranslationMobile(section, sportId, selectionPosition) {
    setTimeout(() => {
      $(`#${section}-number-of-selections-mobile-wrapper-${sportId}-${selectionPosition}`)[0].className = 'c-tag c-tag--translations';
      $(`#${section}-number-of-selections-mobile-${sportId}-${selectionPosition}`).val($(`#${section}-number-of-selections-mobile-${sportId}-${selectionPosition}`).data('defaultvalue'));
    },300)
   
  
  }

  function saveFixedOUMobile(selectedSection, sportId, sectionId, gamesOrder, websiteId, isLive, selectionPosition) {
    const numberOfSelectionForMarket = $(`#${selectedSection}-mobile-ou-${sportId}-${selectionPosition}`)[0].value;
    const gamesIdsOrder = JSON.parse(gamesOrder);
    gamesIdsOrder[0][selectedSection][selectionPosition].ou = numberOfSelectionForMarket || 2.5;

    const successMessage = 'Fixed OU for market updated'
    this.updateSportIdsMobile(websiteId, sectionId, gamesIdsOrder, sportId, selectedSection, successMessage, isLive, selectionPosition);
  }

  function changeToEditFixedOUMobile(section, sportId, selectionPosition) {
    $(`#${section}-mobile-ou-wrapper-${sportId}-${selectionPosition}`)[0].className = 'c-tag c-tag--translations is-editing';
  }

  function cancelEditingFixedOUMobile(section, sportId, selectionPosition) {
    setTimeout(() => {
      $(`#${section}-mobile-ou-wrapper-${sportId}-${selectionPosition}`)[0].className = 'c-tag c-tag--translations';
      $(`#${section}-mobile-ou-${sportId}-${selectionPosition}`).val($(`#${section}-mobile-ou-${sportId}-${selectionPosition}`).data('defaultvalue'));
    }, 300)
  }
  
  function redirectToSingleSportMobile(websiteId) {
    const sportId = $(`#live-page-order-mobile`)[0].value;
    if(sportId == 0) {
      window.location.href = `/websites/${websiteId}/live-config-mobile`
      return;
    }
    window.location.href = `/websites/${websiteId}/live-config-mobile/${sportId}`
  }

  function redirectToSingleSportStandardMobile(websiteId) {
    const sportId = $(`#standard-page-order-mobile`)[0].value;
    if(sportId == 0) {
      window.location.href = `/websites/${websiteId}/standard-config-mobile`
      return;
    }
    window.location.href = `/websites/${websiteId}/standard-config-mobile/${sportId}`
  }

  
  function editMarketAndSaveMobile(section, sportId, selectionId, gamesOrder, key, websiteId, isLive, selectionPosition) {
    const newValue =  $(`#${section}-input-mobile-${sportId}-${key}-${selectionPosition}`)[0].value
    const gamesIdsOrder = JSON.parse(gamesOrder);
    let oldValue = gamesIdsOrder[0][section][selectionPosition].value[key];
    if(newValue === oldValue) {
      errorMessage('Template id is same as old one');
      return;
    }
    if(gamesIdsOrder[0][section][selectionPosition].value.includes(newValue)) {
      errorMessage('This Template id already exists');
      return;
    }
    gamesIdsOrder[0][section][selectionPosition].value[key] = newValue
    const successMessage = 'Successfully edited template id'
    updateSportIdsMobile(websiteId, selectionId, gamesIdsOrder, sportId, section, successMessage, isLive, selectionPosition);
    cancelEditMobile(section, sportId, key, selectionPosition)
  
  }
  
  function selectDefaultMarketValueMobile(selectedSection, sportId, sectionId, gamesOrder, websiteId, isLive, selectionPosition) {
    const selectedMarketType =  $(`#${selectedSection}-selected-value-mobile-${sportId}-${selectionPosition}`)[0].value;
    const gamesIdsOrder = JSON.parse(gamesOrder);

    if (selectedMarketType === 'fixed_ou') {
      gamesIdsOrder[0][selectedSection][selectionPosition]['ou'] = '2.5';
    } else {
      gamesIdsOrder[0][selectedSection][selectionPosition]['ou'] = null;
    }

    if(isRuleChanged(gamesIdsOrder[0][selectedSection][selectionPosition].rule, selectedMarketType)) {
      gamesIdsOrder[0][selectedSection][selectionPosition]['value'] = [];
    }

    gamesIdsOrder[0][selectedSection][selectionPosition]['rule'] = selectedMarketType;
    const successMessage = 'Successfully changed market type'
    updateSportIdsMobile(websiteId, sectionId, gamesIdsOrder, sportId, selectedSection, successMessage, isLive, selectionPosition);
  }

  function isRuleChanged(prevuesValue, newValue) {
    return prevuesValue !== 'static_mix_rule' && newValue === 'static_mix_rule' || prevuesValue === 'static_mix_rule' && newValue !== 'static_mix_rule'
  }
  
  function selectDefaultNumberOfSelectionValueMobile(sportId, sectionId, websiteId, selectionPosition) {
    let selectedNumberOfSelection = $(`#selected-sport-number-of-selections-mobile-mobile-${sportId}-${selectionPosition}`)[0].value;
    selectedNumberOfSelection = selectedNumberOfSelection === 'three_selections' ? 3 : 1;
  
    $.ajax({
      headers: { "X-CSRF-Token": $('meta[name="_token"]').attr("content") },
      url: `/websites/${websiteId}/live-config-mobile/${sectionId}/update-selections-number`,
      type: "POST",
      dataType: "json",
      data: JSON.stringify({ selectedNumberOfSelection }),
      contentType: "application/json; charset=utf-8",
      success: function(response) {
       $(`#list-of-sections-mobile-${sportId}`).html(response.html);
      },
      error: function(data) {
        if (data.status !== 200) {
          errorMessage('Something went wrong');
  
          return;
        }
        successMessage("Successfully changed number of selections")
      }
    });
  }
  
  function addSelectedMarketValueMobile(
    selectedSection,
    sportId,
    sectionId,
    gamesOrder,
    websiteId,
    isLive,
    selectionPosition
  ) {
    const selectedValue = $(`#${selectedSection}-addField-mobile-${sportId}-${selectionPosition}`)[0].value;
    if (!selectedValue) {
      return;
    }
    const gamesIdsOrder = JSON.parse(gamesOrder);
  
    if(gamesIdsOrder[0][selectedSection][selectionPosition].value.includes(selectedValue)) {
      errorMessage('This Template id already exists');
      return;
    }
    gamesIdsOrder[0][selectedSection][selectionPosition].value.push(selectedValue)
  
    const successMessage = 'Successfully add new template id'
    this.updateSportIdsMobile(websiteId, sectionId, gamesIdsOrder, sportId, selectedSection, successMessage, isLive, selectionPosition);
  }
  
  function saveMarketTranslationsMobile(selectedSection, sportId, sectionId, gamesOrder, websiteId, isLive, selectionPosition) {
    const marketTranslationKey =  $(`#${selectedSection}-market-translation-mobile-${sportId}-${selectionPosition}`)[0].value;
    const gamesIdsOrder = JSON.parse(gamesOrder);
    gamesIdsOrder[0][selectedSection][selectionPosition].marketTranslationKey = marketTranslationKey;
    const successMessage = 'Market translation updated'
    this.updateSportIdsMobile(websiteId, sectionId, gamesIdsOrder, sportId, selectedSection, successMessage, isLive, selectionPosition);
  }
  
  function saveSpecialGameTranslationsMobile(selectedSection, sportId, sectionId, gamesOrder, websiteId, isLive, selectionPosition) {
    const specialGameTranslationkey = $(`#${selectedSection}-special-game-translation-mobile-${sportId}-${selectionPosition}`)[0].value;
    const gamesIdsOrder = JSON.parse(gamesOrder);
    gamesIdsOrder[0][selectedSection][selectionPosition].specialGameTranslationkey = specialGameTranslationkey;
    const successMessage = 'Special game translation updated'
    this.updateSportIdsMobile(websiteId, sectionId, gamesIdsOrder, sportId, selectedSection, successMessage, isLive, selectionPosition);
  }
  
  function saveNumberOfSelectionsMobile(selectedSection, sportId, sectionId, gamesOrder, websiteId, isLive, selectionPosition) {
    const numberOfSelectionForMarket = $(`#${selectedSection}-number-of-selections-mobile-${sportId}-${selectionPosition}`)[0].value;
    const gamesIdsOrder = JSON.parse(gamesOrder);
    gamesIdsOrder[0][selectedSection][selectionPosition].numberOfSelectionForMarket = numberOfSelectionForMarket;

  
    const successMessage = 'Number of translation for market updated'
    this.updateSportIdsMobile(websiteId, sectionId, gamesIdsOrder, sportId, selectedSection, successMessage, isLive, selectionPosition);
  }
  
  
  function updateSportIdsMobile(websiteId, sectionId, payload, sportId, selectedSection, message = '', isLive = '0', selectionPosition = false) {
    $.ajax({
      headers: { "X-CSRF-Token": $('meta[name="_token"]').attr("content") },
      url: `/websites/${websiteId}/${isLive == '1' ? 'live' : 'standard'}-config-mobile/${sectionId}`,
      type: "PATCH",
      dataType: "json",
      data: JSON.stringify({ payload, selectedSection }),
      contentType: "application/json; charset=utf-8",
      success: function(response) {
       $(`#list-of-sections-mobile-${sportId}`).html(response.html);
       if(selectionPosition) {
        hideInputFieldMobile(selectedSection, sportId, selectionPosition)
       }
       successMessage(message)
      },
      error: function(data) {
        errorMessage('Something went wrong');
      }
    });
  }
  
  function removeSelectedMarketIdMobile(sectionName, sportId, selectionId, gamesOrder, marketForDeleting, websiteId, isLive, selectionPosition) {
    if(!confirm('Are you sure you want to delete template id?')) {
      return;
    }
  
    const gamesIdsOrder = JSON.parse(gamesOrder); 
    gamesIdsOrder[0][sectionName][selectionPosition].value = gamesIdsOrder[0][sectionName][selectionPosition].value.filter(number => number !== marketForDeleting);
    const successMessage = 'Successfully removed template id'
    updateSportIdsMobile(websiteId, selectionId, gamesIdsOrder, sportId, sectionName, successMessage, isLive, selectionPosition) 
  }
  
  function successMessage(response) {
    const message = `<div class="alert alert-success c-snackbar__message">${response}</div>`;
    $('#successMessage').html(message);
    setTimeout(function() {$('#successMessage').html('')}, 2000);
  }
  
  function errorMessage(response) {
    const message = `<div class="alert alert-danger c-snackbar__message c-snackbar__message--error">${response}</div>`;
    $('#successMessage').html(message);
    setTimeout(function() {$('#successMessage').html('')}, 3000);
  }
  
  function confirmation(sectionId){
    let form = $(`#delete-language-form-${sectionId}`);
    bootbox.dialog({
      message: "Are you sure you want to delete this section?",
      title: 'Are you sure?',
      buttons: {
        success: {
          label: 'Cancel',
          className: 'btn-default pull-left',
          callback: function() {
            bootbox.hideAll();
          }
        },
        danger: {
          label: 'Yes',
          className: 'btn-danger',
          callback: function() {
            form.submit();
          }
        }
      },
      onEscape: function() {
        bootbox.hideAll();
      }
    });
  }

  function addNewSelectionsMobile(sectionName, sportId, selectionId, gamesOrder, websiteId, isLive) {
    const gamesIdsOrder = JSON.parse(gamesOrder);
    const successMessage = 'Added new section'
    gamesIdsOrder[0][sectionName].push({"specialGameTranslationkey":'', 'marketTranslationKey': '', 'numberOfSelectionForMarket': '', 'rule': 'static_value', 'value': []})
    this.updateSportIdsMobile(websiteId, selectionId, gamesIdsOrder, sportId, sectionName, successMessage, isLive);
  }

  function removeSelectionsMobile(sectionName, sportId, selectionId, gamesOrder, websiteId, isLive, $key) {
    if(!confirm('Are you sure you want to delete this section?')) {
      return;
    }
    const gamesIdsOrder = JSON.parse(gamesOrder);
    gamesIdsOrder[0][sectionName].splice($key, 1)
    const successMessage = 'Removed section'
    this.updateSportIdsMobile(websiteId, selectionId, gamesIdsOrder, sportId, sectionName, successMessage, isLive);
  }

  function saveStaticMixRuleMobile(sectionName, sportId, selectionId, gamesOrder, websiteId, isLive, key) {
    const gamesIdsOrder = JSON.parse(gamesOrder); 

    const dataForSaving = [];
    for(let j = 1; j <= 4; j++) { 
      const gameTemplate =  $(`#${sectionName}-input-static-${sportId}-${j}-1-${key}`)[0].value;
      const position =  $(`#${sectionName}-input-static-${sportId}-${j}-2-${key}`)[0].value;
      const translationKey =  $(`#${sectionName}-input-static-${sportId}-${j}-3-${key}`)[0].value;
      dataForSaving.push({gameTemplate, position, translationKey})
    }

    const successMessage = 'Static mix updated'

    gamesIdsOrder[0][sectionName][key].value = dataForSaving;
    this.updateSportIdsMobile(websiteId, selectionId, gamesIdsOrder, sportId, sectionName, successMessage, isLive);

  }

  function moveItemMobile(sectionName, sportId, selectionId, gamesOrder, websiteId, isLive, key, isMovingUp = false) {
    const moveUp = isMovingUp ? Number(key) - 1 : Number(key) + 1;
    const gamesIdsOrder = JSON.parse(gamesOrder); 
    let newValue = gamesIdsOrder[0][sectionName][moveUp];
    gamesIdsOrder[0][sectionName][moveUp] = gamesIdsOrder[0][sectionName][key];
    gamesIdsOrder[0][sectionName][key] = newValue;
    const successMessage = 'Order change'

    this.updateSportIdsMobile(websiteId, selectionId, gamesIdsOrder, sportId, sectionName, successMessage, isLive);
  }