var gamesIncluded = [];
var pageCounter = 0;

$('input[name=is_for_desktop], input[name=is_for_mobile]').change(function(e) {
  if (!$('input[name=is_for_desktop]:checked, input[name=is_for_mobile]:checked').length) {
    $(this).prop('checked', 'checked');
  }
});

$(document).ready(function() {
  if ($('#games-included').length){   // check if div with id 'games-included' exist
    let variableFromLaravel=$("#games-included").text();
    variableFromLaravel = 
      variableFromLaravel.replace(/ /g,'')
        .split("\n");
    variableFromLaravel.shift();
    variableFromLaravel.pop();

    gamesIncluded = variableFromLaravel;

    setGamesInluded(variableFromLaravel);
  }
  
});

function setGamesInluded(games) {
  let splitedGames = games.slice(pageCounter, 10);

  _.each(splitedGames, game =>{
    $('.promotions-section .promotion-list').append(`<a class="list-group-item">${game}</a>`);
  });
}

function goToNextPage() {
  if (pageCounter < _.get(gamesIncluded, 'length')) {
    pageCounter = pageCounter + 10;
    let games = _.clone(gamesIncluded);
    setPromotionList(games);
  }
}

function goToPreviousPage() {
  if (pageCounter > 0) {
    let games = _.clone(gamesIncluded);
    pageCounter = pageCounter - 10;
    setPromotionList(games);
  }
}

function setPromotionList(games) {
  let splitedGames = games.splice(pageCounter, 10);
  $('.promotions-section .promotion-list').remove();
  $('.promotions-section').append('<div class="promotion-list" id="games-include-list"></div>');
  $(document).ready(function() {
    _.each(splitedGames, game => {
      $('.promotions-section .promotion-list').append(`<a class="list-group-item">${game}</a>`);
    })
  });
}