$("#country-filter").change(function() {
  var countryId = $(this).val();
  var url;

  if (countryId > 0) {
    url = '/country-configuration/' + countryId;
  } else {
    url = '/country-configuration';
  }

  window.location = url;
});