const indexOfOtherTab = 4;

function init() {
  $( document ).ready(function() {
    if (!jQuery("#offer-today-form").length) {
      return;
    }

    var form = jQuery("#offer-today-value").val() !== "" ? JSON.parse(jQuery("#offer-today-value").val()) : "";
    if (form !== '' && form !== undefined) {
      setValuesFromDatabase(form);
    }
    onChangeInputValue();
    formatInputs();
    formatInputsBeforeFormSubmit();
  });
}

function formatInputsBeforeFormSubmit() {
  jQuery("form").submit(function (e) {
    formatInputs();
  });
}

function onChangeInputValue() {
  jQuery("#offer-today-form input[type='text'], #offer-today-form input[type='number']").change(function () {
    formatInputs();
  });
}


function formatInputs() {
  var result = [];

  for (var i = 0; i < indexOfOtherTab; i++) {
    var object = {
      'sportId': jQuery(`#sport-${i+1}-id`).val(),
      'leagueIds': jQuery(`#sport-${i+1}-ids`).val(),
      'numOfMatches': jQuery(`#sport-${i+1}-num-of-matches`).val()
    };

    result.push(object);
  }

  var otherSports = {
    'sportIdOther': jQuery(`#other-sport-ids`).val(),
    'leagueIdsOther': jQuery(`#other-league-ids`).val(),
    'numOfMatchesOther': jQuery(`#other-num-of-matches`).val()
  };

  result.push(otherSports);

  jQuery("#offer-today-value").val(JSON.stringify(result));
}

function setValuesFromDatabase(inputs) {
  if (inputs && inputs[i] && inputs[indexOfOtherTab]) {
    return;
  }

  for (var i = 0; i < 4; i++) {
    jQuery(`#sport-${i+1}-id`).val(inputs[i].sportId)
    jQuery(`#sport-${i+1}-ids`).val(inputs[i].leagueIds)
    jQuery(`#sport-${i+1}-num-of-matches`).val(inputs[i].numOfMatches)
  }
  jQuery(`#other-sport-ids`).val(inputs[4].sportIdOther)
  jQuery(`#other-league-ids`).val(inputs[4].leagueIdsOther)
  jQuery(`#other-num-of-matches`).val(inputs[4].numOfMatchesOther)
}


init();
