(function(bootbox) {
  $('.delete-button, .confirm-button').on('click', function(e) {
    e.preventDefault();
    var $self = $(this);
    var form = $self.closest("form");
    var message = $self.attr("data-confirm-msg");
    bootbox.dialog({
      message: message,
      title: 'Are you sure?',
      buttons: {
        success: {
          label: 'Cancel',
          className: 'btn-default pull-left',
          callback: function() {
            bootbox.hideAll();
          }
        },
        danger: {
          label: 'Yes',
          className: 'btn-danger',
          callback: function() {
            form.submit();
          }
        }
      },
      onEscape: function() {
        bootbox.hideAll();
      }
    });
  });

  $('#back-to-search-btn').on('click', function(e) {
    e.preventDefault();
    var $self = $(this);
    var message = $self.attr("data-confirm-msg");
    bootbox.dialog({
      message: message,
      title: 'Are you sure?',
      buttons: {
        success: {
          label: 'Cancel',
          className: 'btn-default pull-left',
          callback: function() {
            bootbox.hideAll();
          }
        },
        danger: {
          label: 'Yes',
          className: 'btn-success',
          callback: function() {
            window.location.href = $('#back').attr('href');
          }
        }
      },
      onEscape: function() {
        bootbox.hideAll();
      }
    });
  });
})(window.bootbox);
