function openIconLinksModal() {
  $("#icon-links-modal").modal("show");
}

function closeIconLinksModal() {
  $("#icon-links-modal").modal("hide");
}

function printInIconModal(what) {
  $("#icon-links-modal .modal-body").html(what);
}

function loadIconCreateModal() {
  $.get(
    "icon-links/create",
    null,
    function (response) {
      printInIconModal(response);
      openIconLinksModal();
    }
  );
}

function loadIconEditModal(id) {
  $.get(
    "icon-links/" + id + "/edit",
    null,
    function (response) {
      printInIconModal(response);
      openIconLinksModal();
    }
  );
}

$("#add-new-icon-btn").click(function (e) {
  e.preventDefault();
  loadIconCreateModal();
});

$(".edit-icon").click(function (e) {
  e.preventDefault();
  loadIconEditModal($(this).attr("data-id"));
});

$(document).ready(function () {
  function toggleAddSlugButton() {
    let allSlugsFilled = true;
    $('input[name="slug_names[]"]').each(function () {
      if (!$(this).val() || $(this).val().trim() === '') {
        allSlugsFilled = false;
        return false;
      }
    });

    if (allSlugsFilled) {
      $('#add-new-slug').prop('disabled', false);
    } else {
      $('#add-new-slug').prop('disabled', true);
    }
  }

  function toggleSaveButton() {
    let iconName = $('#name').val() ? $('#name').val().trim() : '';
    if (iconName !== '') {
      $('#addNewIconLinks, #updateIcon').prop('disabled', false);
    } else {
      $('#addNewIconLinks, #updateIcon').prop('disabled', true);
    }
  }

  $(document).on('input', '#name, input[name="slug_names[]"]', function() {
    toggleAddSlugButton();
    toggleSaveButton();
  });

  toggleAddSlugButton();
  toggleSaveButton();

  $(document).on('click', '#add-new-slug', function (e) {
    e.preventDefault();

    var newSlugInputGroup = $('<div>')
      .addClass('form-group icon-link-form');

    var newSlugInputGroupInner = $('<div>')
      .addClass('icon-links-input-group mt-2');

    var newSlugInput = $('<input>')
      .attr('type', 'text')
      .attr('name', 'slug_names[]')
      .addClass('form-control icon-links-form-control')
      .attr('placeholder', '')
      .attr('required', 'required');

    var deleteIcon = $('<span>')
      .addClass('fa fa-trash icon-links-input-group-addon delete-slug')
      .css('cursor', 'pointer');

    newSlugInputGroupInner.append(newSlugInput).append(deleteIcon);
    newSlugInputGroup.append(newSlugInputGroupInner);

    $('#slugNameContainer').append(newSlugInputGroup);

    toggleAddSlugButton();
  });

  $(document).on('click', '.delete-slug', function (e) {
    $(this).closest('.icon-links-input-group').remove();
    toggleAddSlugButton();
  });
});
