function openContentFormPage(type) {

  var websiteId = type === 'download'
    ? $('#select-websites-bulk-data').val()
    : $('#select-websites').val();
  var languageId = $('#select-languages').val();

  window.location.href = window.location.href  + '/website/' + websiteId  + '/language/' + languageId + '/' + type;
}

function previousPage(page, type) {
  if (page == 1 ) {
    return;
  }
  $('#paginationPage').val(--page);
  triggerSearchAction(type);
}

function nextPage(page, type) {
  $('#paginationPage').val(++page);
  triggerSearchAction(type);
}

function sortSearchTable(param, type) {
  var orderBy = $('#orderBy').val();
  var order = $('#order').val();

  if (orderBy === 'id' || orderBy === param) {
      order = order !== 'asc' ? 'asc' : 'desc';
      $('#order').val(order);
  }

  if (orderBy !== param) {
    $('#order').val('asc');
  }

  $('#orderBy').val(param);

  triggerSearchAction(type);
}

function triggerSearchAction(type) {
  switch(type) {
    case 1:
      $('#search-leagues').submit();
      break;
    case 2:
      $('#search-regions').submit();
      break;
    case 3:
      $('#search-sports').submit();
      break;
    case 4:
      $('#search-pages').submit();
    case 5:
      $('#search-live-sports').submit();
    case 6:
      $('#search-casino-category').submit();
    case 7:
      $('#search-casino-live-category').submit();
      break;
  }
}

$('#search-leagues').keypress(function(event) {
  if (event.keyCode == 13) {
    $('#paginationPage').val(1);
  }
});

function onSearchButton(type) {
  $('#paginationPage').val(1);
  triggerSearchAction(type);
}

$('#search-leagues').submit(function(event) {
  var $this = $(this);
  var form = new FormData($this[0]);

  var leagueTitle = $('#leagueTitle').val();
  var slug = $('#slug').val();
  var region = $('#region').val();
  var sport = $('#sport').val();
  var leagueId = $('#leagueId').val();
  var created = $('#created').val();
  var modified = $('#modified').val();
  var page = $('#paginationPage').val();

  var isSubmitDisabled = _.isEmpty(leagueTitle)
    && _.isEmpty(slug)
    && _.isEmpty(region)
    && _.isEmpty(sport)
    && _.isEmpty(leagueId)
    && _.isEmpty(created)
    && _.isEmpty(modified);

  if (isSubmitDisabled) {
    openDialog();

    return false;
  }

  $('.loader-wrapper').css('display', 'flex');
  $('#search-leagues-btn').prop('disabled', true);

  $.ajax({
    url : $this.attr('action'),
    type: 'POST',
    data: form,
    processData: false,
    contentType: false,
    success: function(response) {
      $('#website-configuration-search-error').hide();
      $('#table-results').remove();
      $('#pagination').remove();
      $('#results').append(response);
      $('.loader-wrapper').hide();
      $('#search-leagues-btn').prop('disabled', false);
  },
    error: function(error) {
      $('#website-configuration-search-error').show();
      $('.loader-wrapper').hide();
      $('#search-leagues-btn').prop('disabled', false);
    }
  });

  $body.animate({ scrollTop: 0 });

  return false;
});

$('#search-regions').submit(function() {
  var $this = $(this);
  var form = new FormData($this[0]);

  var regionTitle = $('#regionTitle').val();
  var slug = $('#slug').val();
  var region = $('#region').val();
  var sport = $('#sport').val();
  var regionId = $('#regionId').val();
  var created = $('#created').val();
  var modified = $('#modified').val();
  var page = $('#paginationPage').val();


  var isSubmitDisabled = _.isEmpty(regionTitle)
    && _.isEmpty(slug)
    && _.isEmpty(region)
    && _.isEmpty(sport)
    && _.isEmpty(regionId)
    && _.isEmpty(created)
    && _.isEmpty(modified);

  if (isSubmitDisabled) {
    openDialog();

    return false;
  }

  $('.loader-wrapper').css('display', 'flex');
  $('#search-regions-btn').prop('disabled', true);

  $.ajax({
    url : $this.attr('action'),
    type: 'POST',
    data: form,
    processData: false,
    contentType: false,
    success: function(response) {
      $('#website-configuration-search-error').hide();
      $('#table-results').remove();
      $('#pagination').remove();
      $('#results').append(response);
      $('.loader-wrapper').hide();
      $('#search-regions-btn').prop('disabled', false);
    },
    error: function(error) {
      $('#website-configuration-search-error').show();
      $('.loader-wrapper').hide();
      $('#search-regions-btn').prop('disabled', false);
    }
  });

  $body.animate({ scrollTop: 0 });

  return false;
});

$('#search-sports').submit(function() {
  var $this = $(this);
  var form = new FormData($this[0]);

  var sportTitle = $('#sportTitle').val();
  var slug = $('#slug').val();
  var sportId = $('#sportId').val();
  var created = $('#created').val();
  var modified = $('#modified').val();
  var page = $('#paginationPage').val();


  var isSubmitDisabled = _.isEmpty(sportTitle)
    && _.isEmpty(slug)
    && _.isEmpty(sportId)
    && _.isEmpty(created)
    && _.isEmpty(modified);

  if (isSubmitDisabled) {
    openDialog();

    return false;
  }

  $('.loader-wrapper').css('display', 'flex');
  $('#search-sports-btn').prop('disabled', true);

  $.ajax({
    url : $this.attr('action'),
    type: 'POST',
    data: form,
    processData: false,
    contentType: false,
    success: function(response) {
      $('#website-configuration-search-error').hide();
      $('#table-results').remove();
      $('#pagination').remove();
      $('#results').append(response);
      $('.loader-wrapper').hide();
      $('#search-sports-btn').prop('disabled', false);
    },
    error: function(error) {
      console.log(error);
      $('#website-configuration-search-error').show();
      $('.loader-wrapper').hide();
      $('#search-sports-btn').prop('disabled', false);
    }
  });

  $body.animate({ scrollTop: 0 });

  return false;
});

$('#search-pages').submit(function() {
  var $this = $(this);
  var form = new FormData($this[0]);

  var pageTitle = $('#pageTitle').val();
  var slug = $('#slug').val();
  var created = $('#created').val();
  var modified = $('#modified').val();
  var page = $('#paginationPage').val();


  var isSubmitDisabled = _.isEmpty(pageTitle)
    && _.isEmpty(slug)
    && _.isEmpty(created)
    && _.isEmpty(modified);

  if (isSubmitDisabled) {
    openDialog();

    return false;
  }

  $('.loader-wrapper').css('display', 'flex');
  $('#search-pages-btn').prop('disabled', true);

  $.ajax({
    url : $this.attr('action'),
    type: 'POST',
    data: form,
    processData: false,
    contentType: false,
    success: function(response) {
      $('#website-configuration-search-error').hide();
      $('#table-results').remove();
      $('#pagination').remove();
      $('#results').append(response);
      $('.loader-wrapper').hide();
      $('#search-pages-btn').prop('disabled', false);
    },
    error: function(error) {
      console.log(error);
      $('#website-configuration-search-error').show();
      $('.loader-wrapper').hide();
      $('#search-pages-btn').prop('disabled', false);
    }
  });

  $body.animate({ scrollTop: 0 });

  return false;
});

$('#content').summernote({
  height: 300
});

$('.note-btn').attr('title', '');

function openDialog() {
  bootbox.dialog({
    message: 'Please fill minimum one search filed.',
    title: 'Search info',
    buttons: {
      danger: {
        label: 'Close',
        className: 'btn-success btn-primary',
        callback: function() {
          bootbox.hideAll();
        }
      }
    },
    onEscape: function() {
      bootbox.hideAll();
    }
  });
}

$('#update-entities').submit(function() {
  var $this = $(this);
  var form = new FormData($this[0]);
  $('.loader-wrapper').css('display', 'flex');

  $.ajax({
    url : $this.attr('action'),
    type: 'POST',
    data: form,
    processData: false,
    contentType: false,
    success: function(response) {
      $('#website-configuration-update-error').hide();
      $('#website-configuration-update-success').show();
      $('.loader-wrapper').hide();
    },
    error: function(error) {
      $('#website-configuration-update-success').hide();
      $('#website-configuration-search-error').show();
      $('.loader-wrapper').hide();
    }
  });

  return false;
});

$('#update-entities-by-lang').submit(function() {
  var $this = $(this);
  var form = new FormData($this[0]);
  $('.loader-wrapper').css('display', 'flex');

  $.ajax({
    url : $this.attr('action'),
    type: 'POST',
    data: form,
    processData: false,
    contentType: false,
    success: function(response) {
      $('#website-configuration-update-error').hide();
      $('#website-configuration-update-success').show();
      $('.loader-wrapper').hide();
    },
    error: function(error) {
      $('#website-configuration-update-success').hide();
      $('#website-configuration-search-error').show();
      $('.loader-wrapper').hide();
    }
  });

  return false;
});

$("#file-upload").change(function() {
  if (this.files && this.files[0]) {
    $('#uploadedFile').attr('value', this.files[0].name);
  };
});

function uploadFile() {
  if ($("#file-upload").val()) {
    $('#upload-form').submit();
  }
}

function importCSV(websiteId, languageId, previewData) {
  var type = $('input[name="seo-tags-type"]:checked').val();
  var fileName = $('#uploadedFile').val();

  if (!fileName) {
    return;
  }

  $.ajax({
    headers: { 'X-CSRF-Token': $('meta[name="_token"]').attr('content') },
    url : '/seo/bulk-data-import/website/' + websiteId + '/language/' + languageId,
    type: 'POST',
    data: {
      'type': type,
      'previewData': previewData,
      'fileName': fileName
    },
    success: function(response) {
      bootbox.dialog({
        message: 'You’ve successfully imported file: ' + response,
        title: 'Success',
        buttons: {
          success: {
            label: 'Close',
            className: 'btn-success btn-primary'
          }
        },
        onEscape: function() {
          bootbox.hideAll();
        }
      });
    }
  });
}

function clearImportSection() {
  if (!$('#uploadedFile').val()) {
    return;
  }

  bootbox.dialog({
    message: 'Are you sure you want to clear everything?',
    title: 'Warning',
    buttons: {
      success: {
        label: 'No',
        className: 'btn-success pull-left',
        callback: function() {
          bootbox.hideAll();
        }
      },
      danger: {
        label: 'Yes',
        className: 'btn-danger btn-primary',
        callback: function() {
          $('#preview-table-section').hide();
          $('#uploadedFile').attr('value', '');
        }
      }
    },
    onEscape: function() {
      bootbox.hideAll();
    }
  });
}

function clearBulkSelection() {
  $('#sports').val('0');
  $('#regions').val('0');
  $('#preview-table-section').hide();
  $('#h1').prop('checked', true);
  $('#meta-title').prop('checked', true);
  $('#meta-description').prop('checked', true);
  hiddenColumns = [];
}

function selectSport() {
  var sportId = parseInt($('#sports').val(), 10);

  if (sportId > 0) {
    $.ajax({
      url : '/seo/bulk-data/get-regions-for-sport/' + sportId,
      type: 'GET',
      success: function(regions) {
        $('#regions')
            .find('option')
            .remove()
            .end()
            .append('<option value="0">-</option>')
            .append('<option value="-1">ALL LEAGUES</option>')
            .append('<option value="-2">ALL REGIONS</option>');

        regions = _.sortBy(regions, 'name');
        _.forEach(regions, function(region) {
          $('#regions').append($('<option>', {
            value: region.id,
            text: region.name
          }));
        });
      },
      error: function(error) {
        console.log(error);
      }
    });
  }
}

function showBulkDataPreview(websiteId, languageId) {
  var regionId = parseInt($('#regions').val(), 10);
  var sportId = parseInt($('#sports').val(), 10);

  if (shouldHideTable()) {
    return;
  }

  $.ajax({
    url : '/seo/bulk-data/website/' + websiteId + '/language/' + languageId + '/get-preview-data',
    type: 'GET',
    data: {
      'sportId': sportId,
      'regionId': regionId
    },
    success: function(response) {
      if (!response.length) {
        $('#preview-table').append($('<tr><th>No results</th></tr>'));
        $('#preview-table-section').show();

        return;
      }
      $('#preview-table')
          .find('tr')
          .remove()
          .end();

      createHeaderRowForBulkPreviewTable();
      _.forEach(response, function(resp) {
        $('#preview-table').append($(createTableRowsFromData(resp, 'td')));
        _.each(hiddenColumns, function(columnIndex) {
          hideColumn(columnIndex);
        })
      });
      $('#preview-table-section').show();
    },
    error: function(error) {
      console.log(error);
    }
  });
}

function createHeaderRowForBulkPreviewTable() {
  var rows = ['ID', 'H1 - Page Title', 'Meta Title', 'Meta Description'];
  $('#preview-table').append($(createTableRowsFromData(rows, 'th')));
}

function getWhatColumnsToShowBasedOnCheckboxes(data) {
  var columnsToMake = [];
  var showH1 = $('#h1').prop('checked');
  var showMetaTitle = $('#meta-title').prop('checked');
  var showMetaDescription = $('#meta-description').prop('checked');

  columnsToMake.push({ text: data[0], showColumn: true });
  columnsToMake.push({ text: data[1], showColumn: showH1 });
  columnsToMake.push({ text: data[2], showColumn: showMetaTitle });
  columnsToMake.push({ text: data[3], showColumn: showMetaDescription });

  return columnsToMake;
}

var hiddenColumns = [];
function createTableRowsFromData(data, rowType) {
  var row = '<tr>';
  data = _.toArray(data);
  data = getWhatColumnsToShowBasedOnCheckboxes(data);

  _.forEach(data, function(columnData, index) {
    row += '<' + rowType + '>' + columnData.text + '</' + rowType + '>';
    if (!columnData.showColumn) {
      addToHiddenColumns(index + 1);
    }
  });
  return row + '</tr>';
}

function toggleColumn(columnIndex) {
  if ($('#preview-table td:nth-child(' + columnIndex + ')').is(':visible')) {
    hideColumn(columnIndex);
    addToHiddenColumns(columnIndex);
  } else {
    $('#preview-table th:nth-child(' + columnIndex + ')').show();
    $('#preview-table td:nth-child(' + columnIndex + ')').show();
    _.remove(hiddenColumns, (column) => {
      return column === columnIndex;
    });
  }

  if (shouldHideTable()) {
    $('#preview-table-section').hide();

    return;
  } else {
    $('#preview-table-section').show();
  }
}

function hideColumn(columnIndex) {
  $('#preview-table th:nth-child(' + columnIndex + ')').hide();
  $('#preview-table td:nth-child(' + columnIndex + ')').hide();
}

function addToHiddenColumns(columnIndex) {
  if (!_.includes(hiddenColumns, columnIndex)) {
    hiddenColumns.push(columnIndex);
  }
}

function downloadBulkData(websiteId, languageId) {
  if (shouldHideTable()) {
    return;
  }

  var url = getBulkDataForDownloadRoute(websiteId, languageId);
  window.open(url, '_blank');
}

function downloadAllSeoData(websiteId, languageId) {
  var url = '/seo/bulk-data/website/' + websiteId +
    '/language/' + languageId +
    '/download-all-seo-data'

  window.open(url, '_blank');
}

function getBulkDataForDownloadRoute(websiteId, languageId) {
  var regionId = parseInt($('#regions').val(), 10);
  var sportId = parseInt($('#sports').val(), 10);
  var skipH1 = $('#h1').prop('checked') ? 0 : 1;
  var skipMetaTitle = $('#meta-title').prop('checked') ? 0 : 1;
  var skipMetaDescription = $('#meta-description').prop('checked') ? 0 : 1;

  return '/seo/bulk-data/website/' + websiteId +
          '/language/' + languageId +
          '/download-bulk-data?sportId=' + sportId +
          '&regionId=' + regionId +
          '&skipH1=' + skipH1 +
          '&skipMetaTitle=' + skipMetaTitle +
          '&skipMetaDescription=' + skipMetaDescription;
}

function shouldHideTable() {
  var regionId = parseInt($('#regions').val(), 10);
  var sportId = parseInt($('#sports').val(), 10);
  var downloadH1 = $('#h1').prop('checked');
  var downloadMetaTitle = $('#meta-title').prop('checked');
  var downloadMetaDescription = $('#meta-description').prop('checked');

  return regionId === 0 || sportId === 0 || (!downloadH1 && !downloadMetaTitle && !downloadMetaDescription);
}

$('#select-websites').change(function() {
  var websiteId = $('#select-websites').val();
  var languageId = $('#select-languages').val();

  $('.loader-wrapper').css('display', 'flex');

  $.ajax({

    headers: { 'X-CSRF-Token': $('meta[name="_token"]').attr('content') },
    url : '/seo/tags',
    type: 'GET',
    data: {
      'selectedWebsiteId': websiteId,
      'selectedLanguageId': languageId,
    },
    success: function(response) {
      $('#website-configuration-search-error').hide();
      $('#languages-select-box').remove();
      $('#select-box').append(response);
      $('.loader-wrapper').hide();
    },
    error: function(error) {
      console.log(error);
      $('#website-configuration-search-error').show();
      $('.loader-wrapper').hide();
    }
  });
});

$('#select-websites-bulk-data').change(function() {
  var websiteId = $('#select-websites-bulk-data').val();
  var languageId = $('#select-languages').val();
  $('.loader-wrapper').css('display', 'flex');

  $.ajax({

    headers: { 'X-CSRF-Token': $('meta[name="_token"]').attr('content') },
    url : '/seo/bulk-data',
    type: 'GET',
    data: {
      'selectedWebsiteId': websiteId,
      'selectedLanguageId': languageId,
    },
    success: function(response) {
      $('#website-configuration-search-error').hide();
      $('#languages-select-box').remove();
      $('#select-box').append(response);
      $('.loader-wrapper').hide();
    },
    error: function(error) {
      console.log(error);
      $('#website-configuration-search-error').show();
      $('.loader-wrapper').hide();
    }
  });
});

$('#search-live-sports').submit(function() {
  var $this = $(this);
  var form = new FormData($this[0]);

  var sportTitle = $('#sportTitle').val();
  var slug = $('#slug').val();
  var sportId = $('#sportId').val();
  var created = $('#created').val();
  var modified = $('#modified').val();
  var page = $('#paginationPage').val();

  var isSubmitDisabled = _.isEmpty(sportId)
    && _.isEmpty(slug)
    && _.isEmpty(sportId)
    && _.isEmpty(created)
    && _.isEmpty(modified);

  if (isSubmitDisabled) {
    openDialog();

    return false;
  }

  $('.loader-wrapper').css('display', 'flex');
  $('#search-live-sports-btn').prop('disabled', true);

  $.ajax({
    url : $this.attr('action'),
    type: 'POST',
    data: form,
    processData: false,
    contentType: false,
    success: function(response) {
      $('#website-configuration-search-error').hide();
      $('#table-results').remove();
      $('#pagination').remove();
      $('#results').append(response);
      $('.loader-wrapper').hide();
      $('#search-live-sports-btn').prop('disabled', false);
    },
    error: function(error) {
      console.log(error);
      $('#website-configuration-search-error').show();
      $('.loader-wrapper').hide();
      $('#search-live-sports-btn').prop('disabled', false);
    }
  });

  $body.animate({ scrollTop: 0 });

  return false;
});

$('#search-casino-category').submit(function() {
  var $this = $(this);
  var form = new FormData($this[0]);

  var casinoCategoryId = $('#casinoCategoryId').val();
  var page = $('#paginationPage').val();

  var isSubmitDisabled = _.isEmpty(casinoCategoryId);

  if (isSubmitDisabled) {
    openDialog();

    return false;
  }

  $('.loader-wrapper').css('display', 'flex');
  $('#casino-category-btn').prop('disabled', true);

  $.ajax({
    url : $this.attr('action'),
    type: 'POST',
    data: form,
    processData: false,
    contentType: false,
    success: function(response) {
      $('#website-configuration-search-error').hide();
      $('#table-results').remove();
      $('#pagination').remove();
      $('#results').append(response);
      $('.loader-wrapper').hide();
      $('#casino-category-btn').prop('disabled', false);
    },
    error: function(error) {
      console.log(error);
      $('#website-configuration-search-error').show();
      $('.loader-wrapper').hide();
      $('#casino-category-btn').prop('disabled', false);
    }
  });

  $body.animate({ scrollTop: 0 });

  return false;
});
