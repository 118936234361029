selectingGalleryImageFor = '';
var counter = 1;

$(document).on('click','.open-gallery', function() {
  selectingGalleryImageFor = $(this).attr('data-id');
  $('.gallery-wrapper').html('');
  getFolders();
});

function setGalleryTabHeight() {
  $('.gallery-wrapper .tab-content').height(
    $('.gallery-wrapper .gallery').height() -
    $('.gallery-wrapper .gallery h2').outerHeight()
    - 40
  );

  $('.gallery-wrapper .thumbnail-image').height(
    $('.gallery-wrapper .col-xs-4.col-md-2').first().width() - 30
  );
}

$(window).resize(function() {
  if ($('.gallery-wrapper .gallery').length == 1) {
    setGalleryTabHeight();
  }
});

function activateGalleryTabs() {
  $('.gallery-tabs a').click(function (e) {
    e.preventDefault();
    $(this).tab('show');
  })
}

function closeGalleryModal() {
  $('.gallery-wrapper').hide();
  $('.gallery-wrapper').html('');
  selectingGalleryImageFor = '';
}

$('.gallery-wrapper').on('click', '.close-gallery', function(e) {
  e.preventDefault();
  closeGalleryModal();
});

$('.gallery-wrapper').on('click', '#cancel-update-folder', function(e) {
  $('#folder-title').show();
  $('#folder-name').hide();
  $('#edit-folder').show();
})

$('.gallery-wrapper').on('click', '.thumbnail', function(e) {
  e.preventDefault();
  if ($(this).hasClass('active')) {
    $(this).removeClass('active');
    $('.gallery-wrapper .use-image').addClass('disabled');
  } else {
    $('.gallery-wrapper .thumbnail').removeClass('active');
    $(this).addClass('active');
    $('.gallery-wrapper .use-image').removeClass('disabled');
  }
});

$('.gallery-wrapper').on('click', '.use-image', function(e) {
  e.preventDefault();
  counter = 1;
  var image = $('.gallery-wrapper .thumbnail.active').attr('data-id');
  $('#'+selectingGalleryImageFor).val(image);
  $('#'+selectingGalleryImageFor+'-img-div').html('<img src="'+image+'">');
  closeGalleryModal();
});

$('.gallery-wrapper').on('click', '.change-folder', function() {
  var token = $(this).data('token');
  var image = $(this).data('image');
  var folderId = $(this).data('id');
  var currentFolderId = image ? image.folder_id : $(this).data('folder');

  if (image) {
    $.ajax({
      url: '/images/' + image.id,
      type: 'put',
      data: {_method: 'put', _token :token, folder_id: folderId},
      success: function() {
        getDataForCurrentFolder(currentFolderId)
      }
    });
  } else {
    $.ajax({
      url: '/move-all-images/' + currentFolderId,
      type: 'put',
      data: {_method: 'put', _token :token, folder_id: folderId, currentFolderId: currentFolderId},
      success: function() {
        getDataForCurrentFolder(currentFolderId)
      }
    });
  }
})

function getFolders() {
  $('.gallery-wrapper').load('/gallery', function() {
    activateGalleryTabs();
    $('.gallery-wrapper').show();
    setGalleryTabHeight();
    $('#back-button').hide();
  });
}

function getDataForCurrentFolder(folderId) {
  $('.gallery-wrapper').load('/gallery/' + folderId, function() {
    activateGalleryTabs();
    $('.gallery-wrapper').show();
    setGalleryTabHeight();
    $("#previous-button").attr( "disabled", true);
    $('#back-button').show();
  });
}

$('.gallery-wrapper').on('click', '#toggle-button', function(e) {
  $('#folder-title').show();
  $('#folder-name').hide();
  $('#edit-folder').show();
});

$('.gallery-wrapper').on('click', '.delete-gallery-folder', function(e) {
  e.stopPropagation();
  if (confirm('Are you sure?') === true) {
    var folderId = $(this).data('id');
    var token = $(this).data('token');
    var element = $(this).closest('.col-md-2');

    $.ajax({
      url: '/gallery/' + folderId,
      type: 'delete',
      data: {_method: 'delete', _token :token},
      success: function() {
        element.remove();
      },
      error: function(request) {
        $('#loader').hide();
        alert(request.responseText);
      }
    });
  }
});

$('.gallery-wrapper').on('click', '#edit-folder', function(e) {
  $('#folder-title').hide();
  $('#folder-name').show();
  $('#edit-folder').hide();
  $('input#name').val($(this).data("folder"));
});

$('.gallery-wrapper').on('submit', '.edit-folder-form', function(e) {
  e.preventDefault();
  $('#loader').show();
  var folderId = $('#folder-title').data('id');

  var fd = new FormData(this);

  $.ajax({
    url: $(this).attr('action'),
    type: 'post',
    processData: false,
    contentType: false,
    data: fd,
    success: function(response) {
      $('#loader').hide();
      $('#folder-title').show();
      $('#folder-name').hide();
      $('#edit-folder').show();
      getDataForCurrentFolder(folderId);
    },
    error: function(request) {
      $('#loader').hide();
    }
  });
});

$('.gallery-wrapper').on('submit', '.upload-photo-form', function(e) {
    e.preventDefault();
    $('#loader').show();
    var fd = new FormData(this);
    $.ajax({
      url: $(this).attr('action'),
      type: 'post',
      processData: false,
      contentType: false,
      data: fd,
      success: function(response) {
        $('#loader').hide();
        if (response.image_url) {
          getDataForCurrentFolder(response.folder_id);
          return;
        }
        $('#add-folder').show();
        $('#folder-list').show();
        $('#add-folder-form').hide();
        $('#gallery-title').show();
        getFolders();
      },
      error: function(request) {
        $('#loader').hide();
      }
    });
});

$('.gallery-wrapper').on('click', '.delete-gallery-image', function(e) {
  e.stopPropagation();
  if (confirm('Are you sure?') === true) {
    var imageId = $(this).data('id');
    var token = $(this).data('token');
    var element = $(this).closest('.col-md-2');

    $.ajax({
      url: '/images/' + imageId,
      type: 'delete',
      data: {_method: 'delete', _token :token},
      success: function() {
        element.remove();
      },
      error: function(request) {
        $('#loader').hide();
        alert(request.responseText);
      }
    });
  }
});

$('.gallery-wrapper').on('click', '.add-gallery-folder', function() {
  $('#add-folder').hide();
  $('#folder-list').hide();
  $('#add-folder-form').show();
  $('#back-button').show();
  $('#gallery-title').hide();
});

$('.gallery-wrapper').on('click', '#back-button', function() {
  $('.gallery-wrapper').html('');
  counter = 1;
  getFolders();
});

$('.gallery-wrapper').on('click', '.card-group', function() {
  var folderId =  $(this).data("value");
  getDataForCurrentFolder(folderId);
});

$('.gallery-wrapper').on('click', '.pagination-links', function (e) {
  var id = $(this).data('id');
  var folderId = $(this).data('folder');
  var numberOfImages = $(this).data('count');

  if (id === "previous") {
    counter --;
  } else {
    counter ++;
  }

  if (numberOfImages < counter) {
    counter = numberOfImages;
    return;
  } else if (counter < 1) {
    counter = 1;
    return;
  }

  $('.gallery-wrapper').load('/gallery/' + folderId + '?page=' + counter, function() {
    activateGalleryTabs();
    $('.gallery-wrapper').show();
    setGalleryTabHeight();
    if (counter === 1) {
      if (counter === numberOfImages) {
        $("#next-button").attr( "disabled", true);
      }
      return $("#previous-button").attr( "disabled", true);
    } else if (counter === numberOfImages) {
      return $("#next-button").attr( "disabled", true);
    }
    $("#previous-button").attr( "disabled", false);
    $("#next-button").attr( "disabled", false);
  });
});

$('body #wrapper').on('change', '#upload-tab-content input[type="file"]', function () {
  var fileInput = $( this );
  var isFileSelected = fileInput
    && fileInput.context
    && fileInput.context.files
    && fileInput.context.files.length;

  if (isFileSelected) {
    $('#upload-tab-content input[type="submit"]').removeClass('disabled');
  } else {
    $('#upload-tab-content input[type="submit"]').addClass('disabled');
  }
});
