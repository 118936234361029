var $radioButtons = $("input[name$='subdomain-type']");

var $subdomainUrl = $('#subdomain-url');
var $subdomainCountryCodes = $('#subdomain-country-codes');

$urlTextbox = $('#url-text');
$countryCodesTextbox = $('#country-codes-text');
$ipsTextbox = $('#ips-text');
$ipsRangeTextbox = $('#ip-range-text');

checkFirst();

function checkFirst() {
  if ($countryCodesTextbox.val() || $ipsTextbox.val() || $ipsRangeTextbox.val()) {
    checkCountryCodesType();
  } else {
    checkUrlType();
  } 
}

function checkUrlType() {
  $("#subdomain-url").show();
  $("#subdomain-country-codes").hide();
  $("input[name$=subdomain-type][value='url']").prop('checked', true);
}

function checkCountryCodesType() {
  $("#subdomain-url").hide();
  $("#subdomain-country-codes").show();
  $("input[name$=subdomain-type][value='code']").prop('checked', true);
}

$radioButtons.click(function() {
    var val = $(this).val();
    var textVal;

    if (val === 'code') {
      $("#subdomain-url").hide();
      $("#subdomain-country-codes").show();
      $urlTextbox.val('');
    } else {
      $("#subdomain-url").show();
      $("#subdomain-country-codes").hide();
      $countryCodesTextbox.val('');
      $ipsTextbox.val('');
      $ipsRangeTextbox.val('');
    }
});

function showAddressOption() {
  $(".address-type-input").hide();
  $(".address-type-"+$("#address-type").val()).show();
}

$("#address-type").change(function() {
  showAddressOption();
});

$("#addresses-form").submit(function() {
  var type = $("#address-type").val();

  if (type != 'ip') {
    $(this).find("[name='ip']").val('');
  }
  if (type != 'ip-range') {
    $(this).find("[name='ip_from']").val('');
    $(this).find("[name='ip_to']").val('');
  }
});

function refreshRestrictedAddressesForm() {
  if ($("#address-type").length == 1) {
    showAddressOption();
  }
}

refreshRestrictedAddressesForm();

