function openBackofficeUserModal() {
  $("#backoffice-user-modal").modal("show");

  var languageCheckboxes = $('#language-selection').find('input:checkbox');
  var websiteCheckboxes = $('#website-selection').find('input:checkbox');
  var seoWebsiteCheckboxes = $('#website-seo-selection').find('input:checkbox');
  var seoCheckbox = $('#seo-selection').find('input:checkbox');
  var configsCheckboxes = $('#configs-selection').find('input:checkbox');
  var selectedLanguages = [];
  var selectedWebsites = [];
  var selectedConfigs = [];
  var selectedSeoWebsites = [];
  var allowedSeo = false;

  populateWithSelected(languageCheckboxes, selectedLanguages);
  populateWithSelected(websiteCheckboxes, selectedWebsites);
  populateWithSelected(seoWebsiteCheckboxes, selectedSeoWebsites);
  populateWithSelected(configsCheckboxes, selectedConfigs);

  checkSelectAll(languageCheckboxes, 'languages');
  checkSelectAll(websiteCheckboxes, 'websites');
  checkSelectAll(configsCheckboxes, 'configs');
  checkSelectAll(seoWebsiteCheckboxes, 'seoWebsites');

  setLanguageIdsValue();
  setWebsiteIdsValue();
  setAllowedSeoValue();
  setConfigsIdsValue();
  setSeoWebsiteIdsValue();

  $('#role').change(function(e) {
    $("select option:selected").each(function() {
      if ($(this).text() !== 'administrator' && $(this).text() !== 'casino manager') {
        $('#websites-div').show();
        $('#languages-div').show();
        $('#seo-div').show();
        checkIfwebsitesAreChecked();
        restartDidabledCheckboxes(configsCheckboxes);
      } else if ($(this).text() === 'casino manager') {
        $('#websites-div').show();
        $('#languages-div').hide();
        $('#seo-div').hide();
        restartPopulated(configsCheckboxes);
        populateWithSelectedCasinoManagerRole(configsCheckboxes, selectedConfigs);
        checkSelectAll(configsCheckboxes, 'configs');
      } else {
        restartDidabledCheckboxes(configsCheckboxes);
        $('#websites-div').hide();
        $('#languages-div').hide();
        $('#configs-div').hide();
        $('#seo-div').hide();
      }
    });
  })
  .trigger('change');

  $('#checkAllLanguages').click(function() {
    languageCheckboxes.not(this).prop('checked', this.checked);

    selectedLanguages = [];

    if (this.checked) {
      populateWithSelected(languageCheckboxes, selectedLanguages);
    }

    setLanguageIdsValue();
  });

  $('#checkAllWebsites').click(function() {
    websiteCheckboxes.not(this).prop('checked', this.checked);

    selectedWebsites = [];

    if (this.checked) {
      populateWithSelected(websiteCheckboxes, selectedWebsites);
    }

    setWebsiteIdsValue();
    checkIfwebsitesAreChecked();
  });

  $('#checkAllConfigs').click(function() {
    configsCheckboxes.not(this).prop('checked', this.checked);

    selectedConfigs = [];

    if (this.checked) {
      populateWithSelected(configsCheckboxes, selectedConfigs);
    }

    setConfigsIdsValue();
  });

  $('#checkAllSeoWebsites').click(function() {
    seoWebsiteCheckboxes.not(this).prop('checked', this.checked);

    selectedSeoWebsites = [];

    if (this.checked) {
      populateWithSelected(seoWebsiteCheckboxes, selectedSeoWebsites);
    }

    setSeoWebsiteIdsValue();
  });

  languageCheckboxes.click(function(e) {
    addOrRemoveIds(e, selectedLanguages);
    checkSelectAll(languageCheckboxes, 'languages');
  });

  websiteCheckboxes.click(function(e) {
    addOrRemoveIds(e, selectedWebsites);
    checkSelectAll(websiteCheckboxes, 'websites');
    checkIfwebsitesAreChecked();
  });

  configsCheckboxes.click(function(e) {
    addOrRemoveIds(e, selectedConfigs);
    checkSelectAll(configsCheckboxes, 'configs');
  });

  seoWebsiteCheckboxes.click(function(e) {
    addOrRemoveIds(e, selectedSeoWebsites);
    checkSelectAll(seoWebsiteCheckboxes, 'seoWebsites');
  });


  function checkSelectAll(checkboxes, type) {
    var allChecked = true;
    checkboxes.each(function(checkbox) {
      if (!this.checked) {
        allChecked = false;
      }
    });

    if (type === 'websites') {
      $('#checkAllWebsites').prop('checked', allChecked);
    } else if (type === 'languages') {
      $('#checkAllLanguages').prop('checked', allChecked);
    } else if (type === 'configs') {
      $('#checkAllConfigs').prop('checked', allChecked);
    } else if (type === 'seoWebsites') {
      $('#checkAllSeoWebsites').prop('checked', allChecked);
    }
  }

  function addOrRemoveIds(e, array) {
    if (array.indexOf(e.target.value) === -1) {
      array.push(e.target.value);
    } else {
      var index = array.indexOf(e.target.value);
      array.splice(index, 1);
    }
    setLanguageIdsValue();
    setWebsiteIdsValue();
    setConfigsIdsValue();
    setSeoWebsiteIdsValue();
  }

  function setLanguageIdsValue() {
    $('#language-ids').attr('value', selectedLanguages);
  }

  function setWebsiteIdsValue() {
    $('#website-ids').attr('value', selectedWebsites);
  }

  function setSeoWebsiteIdsValue() {
    $('#seo_website_ids').attr('value', selectedSeoWebsites);
  }

  function setAllowedSeoValue() {
    $('#website-allowed-seo').attr('value', allowedSeo);
  }

  function setConfigsIdsValue() {
    $('#allowed-config-tabs').attr('value', selectedConfigs);
  }

  function checkIfwebsitesAreChecked() {
    if (selectedWebsites.length) {
      $('#configs-div').show();
    } else {
      $('#configs-div').hide();
    }
  }

  function populateWithSelected(checkboxes, array) {
    checkboxes.each(function() {
      if (this.checked) {
        array.push($(this).val());
      }
    });
  }

  function populateWithSelectedCasinoManagerRole(checkboxes, array) {
    checkboxes.each(function() {
      if (
        this.defaultValue === 'mini-games' ||
        this.defaultValue === 'casino' || 
        this.defaultValue === 'casino-mobile-content' || 
        this.defaultValue === 'casino-playtech' || 
        this.defaultValue === 'casino-playtech-mobile-content'
      ) {
        this.checked = true;
      }
      if (array.indexOf(this.defaultValue) !== -1) {
        var index = array.indexOf(this.defaultValue);
        array.splice(index, 1);
      }
      $(this).attr('disabled', true);
    });

    populateWithSelected(checkboxes, array);
    checkSelectAll(configsCheckboxes, 'configs');
    setConfigsIdsValue();
    
    $('#checkAllConfigs').attr('checked', false);
    $('#checkAllConfigs').attr('disabled', true);
  }
}

function restartPopulated(checkboxes) {
  checkboxes.each(function() {
    this.checked = false;
  })
}

function restartDidabledCheckboxes(checkboxes) {
  checkboxes.each(function() {
    $(this).attr('disabled', false);
    $('#checkAllConfigs').attr('disabled', false);
  })
}

function closeBackofficeUserModal() {
  $("#backoffice-user-modal").modal("hide");
}

function openPasswordChangeModal(user) {
  $("#error-message").hide();
  $("#password-change-modal").modal("show");
  $("#password-change-modal .modal-title").text(user['name']);
  $("#user-id").val(user['id']);
}

function closePasswordChangeModal() {
  $("#password-change-modal").modal("hide");
  $("#password, #password2").val('');
  $("#password-change-modal .modal-title").text('Password Change');
}

function printInBackofficeUsersModal(what) {
  $("#backoffice-user-modal .modal-body").html(what);
}

function loadBackofficeUserCreateModal() {
  $.get(
    "backoffice-users/create",
    null,
    function(response) {
      printInBackofficeUsersModal(response);
      openBackofficeUserModal();
    }
  );
}

function loadBackofficeUserEditModal(id) {
  $.get(
    "backoffice-users/" + id + "/edit",
    null,
    function(response) {
      printInBackofficeUsersModal(response);
      openBackofficeUserModal();
    }
  );
}

$("#add-new-user-btn").click(function(e) {
  e.preventDefault();
  loadBackofficeUserCreateModal();
});

$(".edit-user").click(function(e) {
  e.preventDefault();
  loadBackofficeUserEditModal($(this).attr("data-id"));
});

$(".change-password").click(function(e) {
  e.preventDefault();
  var user = $(this).attr("data-id") ? JSON.parse($(this).attr("data-id")) : {};
  openPasswordChangeModal(user);
});


$("#update-password").click(function(e) {
  var pass1 = $("#password").val();
  var pass2 = $("#password2").val();
  var userId = $("#user-id").val();
  var url = userId ? '/backoffice-admin-change-password/' + userId : '/backoffice-change-password/';
  if (pass1 !== pass2) {
    $("#error-message").text('Passwords do not match').fadeIn();
    return;
  }
  if (pass1.length < 1) {
    $("#error-message").text('Password cannot be empty').fadeIn();
    return;
  }
  e.target.disabled = true;
  $.ajax({
    method: 'PUT',
    headers: {
      'X-CSRF-Token': $('meta[name="_token"]').attr('content')
    },
    url: url,
    data: {
      newPass: pass1
    }
  }).done(function() {
    closePasswordChangeModal();
    e.target.disabled = false;
    message = `
      <div id="flash" class="row">
        <div class="col-md-12">
          <div class="alert alert-success">
            You have successfully changed user's password.
          </div>
        </div>
      </div>`;
    $(".padding15").prepend(message);
    setTimeout(function() { $("#flash").fadeOut('slow')}, 2500);
  });
});


$('body').on('submit', '#users-form', function(e) {
    var $this = $(this);
    var form = new FormData($this[0]);
    e.preventDefault();

    $.ajax({
      url : $this.attr('action'),
      type: 'POST',
      data: form,
      processData: false,
      contentType: false,
      success: function(response) {
        if (response === 'User successfully created.' || response === 'User successfully updated.') {
          closeBackofficeUserModal();
          message = `<div class="alert alert-success">${response}</div>`;
          $('#successMessage').html(message);
          setTimeout('location.reload(true)', 1000);
        } else {
          message = `<div class="alert alert-danger">${response}</div>`;
          $('#errorMessage').html(message);
        }
      },
      error: function(data) {
      }
    });
});
